import React, { FC, ReactNode, useContext, useState } from 'react'

import { links } from '../../../routes'
import { Link, NavLink, useNavigate } from 'react-router-dom'

import { LogoIcon } from '../../../assets/illustration'
import { titlePage } from '../../../utils/functions'
import { UserAuthContext } from '../../../../context/AuthContext'

interface MenuLayoutParams {
  title?: string, 
  here?: string, 
  isMin?: boolean,
  children?: ReactNode
}

export const MenuLayout:FC<MenuLayoutParams> = ({title, here, isMin = false, children}) => {
  titlePage(title)
  let navigate = useNavigate()
  const { info, logOut } = useContext(UserAuthContext);

  const [reduceMenu, setReduceMenu] = useState<boolean>(false)
  const [openAccountMenu, setOpenAccountMenu] = useState<boolean>(false)
  // const [isNewNotif, setIsNewNotif] = useState<boolean>(false)

  let navItems = [
    {
      icon: "icon-layer-group",
      link: links.products,
      label: "Articles",
      here: "product",
      access: true,
    },
    {
      icon: "icon-list-ul-alt",
      link: links.subcategories,
      label: "Sous-Categories",
      here: "subcategory",
      access: true,
    },
    {
      icon: " icon-clipboard-list",
      link: links.categories,
      label: "Categories",
      here: "category",
      access: true,
    },
    {
      icon: "icon-user",
      link: links.users,
      label: "Clients",
      here: "client",
      access: true,
    },
    {
      icon: "icon-user-shield",
      link: links.admins,
      label: "Administrateurs",
      here: "admin",
      access: true,
    },
  ]

  return (
    <>
      <header className='kbn-header'>
        <div className='kbn-header-logo'>
            <Link to={links.home}>
              <LogoIcon/>
            </Link>
        </div>
        <div className='kbn-header-account'>
          
          <button className={`kbn-header-account-btn ${here === "notif" ? "active" : ""}`} type='button' onClick={() => navigate(links.notif)}>
              <i className="icon-bell-alt"></i>
              <span className='kbn-header-account-tooltip'>Notification</span>
              <span className='notif'></span>
          </button>
          
          <div className='kbn-header-account-structure'>
            <span>Administration</span>
          </div>
            
          <div className='kbn-header-account-user'>
            <button className='kbn-header-account-user-btn' type='button' onClick={() => setOpenAccountMenu(!openAccountMenu)}>
              <span>{info?.firstName + " " + info?.lastName?.toUpperCase()}</span>
              <div className='kbn-header-account-user-btn__avatar'>
                {
                  info?.avatar ? (
                    <img src={info?.avatar} alt="avatar"/>
                  ) : (
                    <i className="icon-user"></i>
                  )
                }
              </div>
            </button>
            <div className={`kbn-header-account-user-menu ${openAccountMenu ? "" : "is--inactive"}`}>
                <div className='kbn-header-account-user-menu__item'>
                  <NavLink to={links.account} end>
                    <i className="kbn-icon-user"></i>
                    <span>Mon compte</span>
                  </NavLink>
                </div>
                <div className='kbn-header-account-user-menu__item'>
                  <NavLink to={links.account_info} end>
                    <i className=" kbn-icon-gear"></i>
                    <span>Paramètres</span>
                  </NavLink>
                </div>
                <div className='kbn-header-account-user-menu__item'>
                  <button type='button' className='danger' onClick={() => logOut()}>
                    <i className="kbn-icon-log-out"></i>
                    <span>Deconnexion</span>
                  </button>
                </div>
            </div>
          </div>
        </div>
      </header>
      <nav className={`kbn-navigation ${isMin ? (reduceMenu ? "is--unreduce" : "is--min") : reduceMenu ? "is--reduce" : ""}`}>
          
          <div className='kbn-navigation__container'>
              <button type='button' className={`kbn-navigation-btn`} onClick={() => setReduceMenu(!reduceMenu)}>
                <i className={isMin ? (reduceMenu ? "icon-arrow-narrow-left" : "icon-arrow-narrow-right") : reduceMenu ? "icon-arrow-narrow-right" : "icon-arrow-narrow-left"}/>
              </button>
              <ul className="kbn-navigation__list">
                <li className="kbn-navigation__item kbn-navigation-item is--special">
                  <NavLink className={here === "home" ? "active" : ""} to={links.home} end>
                    <i className="icon-house-line"></i>
                    <span>Tableau de bord</span>
                  </NavLink>
                </li>
                {
                  navItems?.filter((el: any) => el.access).length > 0 ? (
                    navItems?.filter((el: any) => el.access).map((item: any, index: number) => (
                      <li className={`kbn-navigation__item kbn-navigation-item`} key={index}>
                        <NavLink className={here === item?.here ? "active" : ""} to={item.link} end>
                          <i className={item.icon}></i>
                          <span>{item?.label}</span>
                        </NavLink>
                      </li>
                    ))
                  ) : (
                    <li className='kbn-navigation__not'>
                      <span>Vous n'avez aucun droit pour le moment</span>
                    </li> 
                  )
                }
              </ul>
          </div>
      </nav>
      <main className={`kbn-main ${isMin ? (reduceMenu ? "is--unreduce" : "is--min") : reduceMenu ? "is--reduce" : ""}`} role={"main"}>
        {children}
      </main>
      {/* {
        newNotification ? (
          <Toast id={"alert-notification"} status={"st-success"} fnc={() => setNewNotification(false)}>
            <p>{notificationContent?.description_fr  || "Vous avez reçu une nouvelle notification"}</p>
          </Toast>
        ) : null
      } */}
    </>
  )
}
