import { Link, useNavigate } from "react-router-dom";

import { useContext, useEffect, useState } from "react";
import { UserAuthContext } from "../../../../../context/AuthContext";
import { links } from "../../../../routes";
import { MenuLayout } from "../../../../layout";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { deleteUser, getUsers, reset } from "../../../../../app/controller/user/user.slice";
import { Button, ModalLayout } from "../../../../components";
import { MeditationCuate } from "../../../../assets/illustration";

const DeleteModal = ({item, closeFnc}:{item: any, closeFnc: (val: boolean) => void}) => {
  const dispatch = useAppDispatch();
  const {isDeleteSuccess, isDeleteLoading, isDeleteError, message } = useAppSelector((state) => state.user);
  
  const [statut, setStatut] = useState<boolean>(false)
  const closeModal = () => {
    if(isDeleteSuccess && statut){
      window.location.reload()
    }
    setStatut(false)
    closeFnc(false)
  }

  return (
    
    <ModalLayout size={"is--md"} isOpen={true} id={"add-modal"} withClose={!isDeleteLoading} fnc={() => closeModal()}>
      <div className={"kbn-modal-form"}>
        {
          statut && isDeleteSuccess ? (
            <div className={"kbn-modal-form-status"}>
                <div className={"kbn-modal-form-status__container"}>
                    <div className={"kbn-modal-form-status__icon is--success"}>
                        <i className="icon-party-bell"/>
                    </div>
                    <div className={"kbn-modal-form-status__text"}>
                        <h2>Suppression d'un administrateur</h2>
                        <p>Votre demande de suppression a bien été envoyée avec succès</p>
                        <div className={"kbn-modal-form-status__action"}>
                            <Button
                                btnType={"button"}
                                label={ "Fermer..."}
                                btnStyle={"is--neutral"}
                                active={true}
                                withIconLeft={false}
                                onClick={() => {
                                    closeModal()
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
          ) : (
            <div className={"kbn-modal-form-status"}>
                <div className={"kbn-modal-form-status__container"}>
                    <div className={"kbn-modal-form-status__icon is--danger"}>
                      <i className=" icon-ban"/>
                    </div>
                    <div className={"kbn-modal-form-status__text"}>
                        <h2>Suppression d'un administrateur</h2>
                        {
                            statut && isDeleteError ? (
                              <p>
                                  <strong>Ouppss!!!</strong> <br/>
                                  <span>{message}</span>
                              </p>
                            ) : (
                                <p>Souhaitez-vous vraiment bloquer cet élément</p>
                            )
                        }
                        <div className={"kbn-modal-form-status__action"}>
                            {
                                statut && isDeleteError ? (
                                    <Button
                                        btnType={"button"}
                                        label={isDeleteLoading ? "Envoi en cours" : "Réessayer..."}
                                        btnStyle={"is--danger"}
                                        active={!isDeleteLoading}
                                        withIconLeft={true}
                                        iconLeftClass={isDeleteLoading ? "icon-spin" : " icon-ban"}
                                        onClick={() => {
                                          
                                          setStatut(true)
                                          dispatch(deleteUser(item.id))
                                        }}
                                    />
                                ) : (
                                    <Button
                                        btnType={"button"}
                                        label={isDeleteLoading ? "Suppression en cours" : "Bloquer"}
                                        btnStyle={"is--danger"}
                                        active={!isDeleteLoading}
                                        withIconLeft={true}
                                        iconLeftClass={isDeleteLoading ? "icon-spin" : " icon-ban"}
                                        onClick={() => {
                                          
                                          setStatut(true)
                                          dispatch(deleteUser(item.id))
                                        }}
                                    />
                                )
                            }

                        </div>
                    </div>
                </div>
            </div>
          )
        }
      </div>
    </ModalLayout>
  )
}

export const UsersPage = () => {
  const { token, info } = useContext(UserAuthContext);
  let navigate = useNavigate()

  const [search, setSearch] = useState<string>("")
  const [numberItem, setNumberItem] = useState<number>(20)
  const [page, setPage] = useState<number>(1)

  const [deleteModal, setDeleteModal] = useState<boolean>(false)

  const [itemModal, setItemModal] = useState<any>(null)
  const dispatch = useAppDispatch();
  const [chooseApercu, setChosseApercu] = useState<string>("grid")
  
  const { isLoading, isSuccess, isError, users, users_info, message } = useAppSelector((state) => state.user);

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.users}`)
    }
  }, [token, navigate]);

  useEffect(() => {
    if(page && page >= 0 && numberItem && numberItem > 1){
      dispatch(getUsers({
        page: page - 1,
        size: numberItem,
      }))
    }
    return () => {
        dispatch(reset())
    }
  }, [dispatch, page, numberItem])
    
    return (
      <MenuLayout title="Clients" isMin={true} here="client">
        <div className='kbn-page'>
        <div className='kbn-page-arianne'>
          <Link to={links.home}>
            <span>Accueil</span>
          </Link>
          <span>/</span>
          <p className='active'>
            <span>Clients</span>
          </p>
        </div>
        <div className='kbn-page-title'>
          <h1>Les clients</h1>
        </div>
        <div className='kbn-page-actions'>
          <div className='kbn-page-actions__main'>
           
            <div className='kbn-page-search'>
              <i className="icon-search-alt"></i>
              <input type="search" placeholder='Rechercher....' value={search} onChange={(e) => setSearch(e.target.value)}/>
            </div>
          </div>
          <div className='kbn-page-actions__side'>
            <div className='kbn-page-numbers'>
              <span>Nombre à afficher</span>
              <input type="number" value={numberItem} onChange={(e) => setNumberItem(parseInt(e.target.value))} min={2} />
            </div>
            {
              users_info?.totalPages > 1 ? (
                <div className='kbn-page-numbers'>
                  <span>Page: </span>
                  <input type="number" value={page} onChange={(e) => setPage(parseInt(e.target.value))} min={1} />
                  <span>/ {users_info?.totalPages || ""}</span>
                </div>
              ) : (
                <div className='kbn-page-numbers'>
                  <span>Page: 1/1</span>
                </div>
              )
            }
            <div className='kbn-page-btns'>
              <button type='button' onClick={() => setChosseApercu("grid")} className={`kbn-page-btn ${chooseApercu === "grid" ? "is--active" : ""}`}>
                <i className="icon-grid"></i>
              </button>
              <button type='button' onClick={() => setChosseApercu("list")} className={`kbn-page-btn ${chooseApercu === "list" ? "is--active" : ""}`}>
                <i className="icon-list-ul-alt"></i>
              </button>
            </div>
          </div>
        </div>
        {
          isLoading ? (
            <div className={"kbn-loader2"}>
              <i className="icon-spin"/>
            </div>
          ) : (
            isError ? (
              <div className={"kbn-empty-status"}>
                <div className='kbn-empty-status__illu'>
                  <MeditationCuate/>
                </div>
                <div className='kbn-empty-status__container'>
                  <strong>Oupss!! Une erreur est survenue</strong>
                  {message}
                  <button type='button' onClick={() => window.location.reload()} style={{textDecoration: "underline"}}>Reactualiser...</button>
                </div>
              </div>
            ) : (
              isSuccess && users && users_info ? (
                <>
                  {
                    users
                    .filter((el: any) => el?.id !== info?.id)
                    .filter((el: any) => el?.firstName?.toLowerCase().includes(search.toLowerCase()) || el?.lastName?.toLowerCase().includes(search.toLowerCase()))
                    .length > 0 ? (
                      chooseApercu === "grid" ? (
                        <div className='kbn-page-grid'>
                          {
                            
                          users
                            .filter((el: any) => el?.id !== info?.id)
                            .filter((el: any) => el?.firstName?.toLowerCase().includes(search.toLowerCase()) || el?.lastName?.toLowerCase().includes(search.toLowerCase()))
                            .map((item, index) => (
                              <div className='kbn-page-grid-item' key={index}>
                                <div className='kbn-page-card'>
                                  <div className='kbn-page-card__avatar'>
                                    {
                                      item?.avatar ? (
                                          <img src={item?.avatar} alt="avatar"/>
                                      ) : (
                                          <i className="icon-user"></i>
                                      )
                                    }
                                  </div>
                                  <div className='kbn-page-card__container'>
                                    <strong>{item?.firstName + " " +item?.lastName?.toUpperCase()}</strong>
                                    <div><span className="kbn-tag">{item.role}</span></div>
                                    
                                    <p> Email:  {item?.email || "ND"}</p>
                                  </div>
                                  <div className='kbn-page-card__actions'>
                                    <button type='button' className='kbn-page-card__action' onClick={() => navigate(links.user + item.id)}>
                                      <i className="icon-user-pen"></i>
                                      <span>Consulter le profile</span>
                                    </button>
                                   
                                    <button type='button' className='kbn-page-card__action is--danger' onClick={() => {
                                      setItemModal(null)
                                      setItemModal(item)
                                      setDeleteModal(true)
                                    }}>
                                      <i className=" icon-ban"></i>
                                      <span>Bloquer</span>
                                    </button>
                                          
                                      
                                  </div>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      ) : (
                        <div className='kbn-page-list'>
                          <div className='kbn-page-list__row kbn-page-list-row is--header'>
                            <div className='kbn-page-list-col is--min'>
                              <strong>#</strong>
                            </div>
                            <div className='kbn-page-list-col is--auto'>
                              <strong>Client</strong>
                            </div>
                            <div className='kbn-page-list-col is--mid'>
                              <strong>Type</strong>
                            </div>
                            <div className='kbn-page-list-col is--mid'>
                              <strong>Actions</strong>
                            </div>
                          </div>
                          <div className='kbn-page-list__body kbn-page-list-body'>
                            {
                              users
                              .filter((el: any) => el?.id !== info?.id)
                              .filter((el: any) => el?.firstName?.toLowerCase().includes(search.toLowerCase()) || el?.lastName?.toLowerCase().includes(search.toLowerCase()))
                              .map((item, index) => (
                                <div className='kbn-page-list-row' key={index}>
                                  <div className='kbn-page-list-col is--min'>
                                    <strong>{index + 1}</strong>
                                  </div>
                                  <div className='kbn-page-list-col is--auto'>
                                    <div className='kbn-page-list-user'>
                                      <div className='kbn-page-list-user__avatar'>
                                        {
                                          item?.avatar ? (
                                              <img src={item?.avatar} alt="avatar"/>
                                          ) : (
                                              <i className="icon-user"></i>
                                          )
                                        }
                                      </div>
                                      <div className='kbn-page-list-user__container'>
                                          <strong>{item?.firstName + " " +item?.lastName?.toUpperCase()}</strong>
                                          <p>Email:  {item?.email || "ND"}</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='kbn-page-list-col is--mid'>
                                    <div><span className="kbn-tag">{item.role}</span></div>
                                  </div>
                                  <div className='kbn-page-list-col is--mid'>
                                    <button type='button' className='kbn-page-list-btn' onClick={() => navigate(links.admin + item?.id)}>
                                      <i className="icon-user-pen"></i>
                                      <span>Consulter le profile</span>
                                    </button>
                                    <button type='button' className='kbn-page-list-btn is--danger' onClick={() => {
                                      setItemModal(null)
                                      setItemModal(item)
                                      setDeleteModal(true)
                                    }}>
                                      <i className="  icon-ban"></i>
                                      <span>Bloquer</span>
                                    </button>
                                     
                                  </div>

                                </div>
                              ))
                            }

                          </div>
                        </div>
                      )
                    ) : (
                      <div className={"kbn-empty-status"}>
                        <div className='kbn-empty-status__illu'>
                          <MeditationCuate/>
                        </div>
                        <div className='kbn-empty-status__container'>
                          <strong>La liste est vide.</strong>
                        </div>
                      </div>
                    )
                  }
                </>
              ) : null
            )
          )
        }

        {
          itemModal && deleteModal ? <DeleteModal item={itemModal} closeFnc={setDeleteModal} /> : null
        }
      </div>
      </MenuLayout>
    )
  }
  