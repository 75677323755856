import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { MenuLayout } from '../../../../../layout'
import { SettingMenu } from '../module/settingMenu'
import { links } from '../../../../../routes'
import { Link, useNavigate } from 'react-router-dom'
import { AlertModule, Button, Textfield } from '../../../../../components'
import { useAppDispatch, useAppSelector } from '../../../../../../app/store'
import { reset, updatePassword } from '../../../../../../app/controller/auth/auth.slice'
import { UserAuthContext } from '../../../../../../context/AuthContext'



const EditMyPassword = ({info}:{info: any}) => {
  const dispatch = useAppDispatch();
  const { isPasswordLoading, isPasswordError, isPasswordSuccess, message } = useAppSelector((state) => state.auth);
  const [data, setData] = useState<any>({
    oldPassword: "", 
    newPassword: "", 
    confirmPassword: "",
    userId: info?.id
  })
  
  const [error, setError] = useState<any>({
    field: "",
    message: ""
  })

  const handleSumbit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(!data.oldPassword){
      setError({
          field: "oldPassword",
          message: "Veuillez renseigner ce champs"
      })
      return
  }
  if(!data.newPassword){
      setError({
          field: "newPassword",
          message: "Veuillez renseigner ce champs"
      })
      return
  }
  if(!data.confirmPassword){
      setError({
          field: "confirmPassword",
          message: "Veuillez renseigner ce champs"
      })
      return
  }
  setError(null)
  dispatch(updatePassword(data))
} 

  return (
   
    <div className='kbn-page-parametre__box kbn-page-parametre-box'>
      {/* <h2>Mes informations</h2> */}
      <form className='kbn-page-parametre-box__form' onSubmit={handleSumbit}>
        {
          isPasswordSuccess ? (
            <AlertModule status={"st-success"} title={"Succès!!!"} message={"Vos informations a été modifiées avec succès"}/>
          ) : (
              isPasswordError ? (
                  <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
          )
        }
        <div className='kbn-page-parametre-box__fields'>
          <div className='kbn-page-parametre-box__field'>
            <Textfield
              id={"oldPassword"}
              value={data.oldPassword}
              require={true}
              changeValue={(value: any) => setData({...data, oldPassword: value})}
              type={"password"}
              label={"Ancien mot de passe"}
              placeholder={"Eg: *****"}
              error={error}
            />
          </div>
          <div className='kbn-page-parametre-box__field'>
            <Textfield
              id={"newPassword"}
              value={data.newPassword}
              require={true}
              changeValue={(value: any) => setData({...data, newPassword: value})}
              type={"password"}
              label={"Nouveau mot de passe"}
              placeholder={"Eg: *****"}
              error={error}
            />
          </div>
          <div className='kbn-page-parametre-box__field'>
            <Textfield
              id={"confirmPassword"}
              value={data.confirmPassword}
              require={true}
              changeValue={(value: any) => setData({...data, confirmPassword: value})}
              type={"password"}
              label={"Confirmation du nouveau mot de passe"}
              placeholder={"Eg: *****"}
              error={error}
            />
          </div>
        </div>
        <div className='kbn-page-parametre-box__action'>
          <Button btnType={"submit"}label={isPasswordLoading ? "Modification en cours..." : "Enregistrer les modifications"} btnStyle={"is--primary"} active={!isPasswordLoading} withIconLeft={true} iconLeftClass={isPasswordLoading ? "kbn-icon-spin6" : "kbn-icon-save"}/>
        </div>
      </form>
    </div>
  )
}

export const EditPasswordPage = () => {
  const { token, info } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.account_password}`)
    }
  }, [token, navigate]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(reset())
    }
  }, [dispatch])

  return (
    <MenuLayout title="Paramètre - Mot de passe" isMin={true}>
      <div className='kbn-page is--grid'>
        <div className='kbn-page__side'>
          <SettingMenu here='password'/>
        </div>
        <div className='kbn-page__container'>

          <div className='kbn-page-arianne'>
            <Link to={links.home}>
              <span>Accueil</span>
            </Link>
            <span>/</span>
            <Link to={links.account}>
              <span>Mon compte</span>
            </Link>
            <span>/</span>
            <p className='active'>
              <span>Mot de passe</span>
            </p>
          </div>

          <div className='kbn-page-title'>
            <h1>Mot de passe</h1>
          </div>
          <div className='kbn-page-parametre'>

            <div className='kbn-page-parametre__container'>
              {info && <EditMyPassword info={info}/>}
            </div>
          </div>
        </div>
      </div>
    </MenuLayout>
  )
}
