import {FC} from "react";
import {ButtonInterface} from "../interface/ButtonInterface";

export const Button:FC<ButtonInterface> = ({active, btnType, label, iconRightClass, withIconLeft, withIconRight, iconLeftClass, onClick, btnStyle}) => {
  return (
    <>
      {
        btnType === "submit" ? (
          <button type={"submit"} className={`kbn-button ${active ? btnStyle : "is--disable"}`} disabled={!active}>
            {
              withIconLeft && (
                <i className={iconLeftClass}></i>
              )
            }
            <span>{label}</span>
            {
              withIconRight && (
                <i className={iconRightClass}></i>
              )
            }
          </button>
        ) : (
          <button type={btnType} className={`kbn-button ${active ? btnStyle : "is--disable"}`} onClick={() => {
            if(active){
              onClick && onClick()
            }
          }} disabled={!active}>
            {
              withIconLeft && (
                <i className={iconLeftClass}></i>
              )
            }
            <span>{label}</span>
            {
              withIconRight && (
                <i className={iconRightClass}></i>
              )
            }
          </button>
        )
      }
    </>
  );
};
