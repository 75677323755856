import {FC, useEffect, useState} from 'react';
import {TextfieldParams} from "../interface/TextfieldInterface";

export const Textfield:FC<TextfieldParams> = ({id, max, min, type, error, label, placeholder, tooltip, require, value, changeValue}) => {
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [typeField, setTypeField] = useState<string>("")
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    changeValue(e.target.value)
    let valeur = e.target.value
    if(require){
      if(!valeur){
        setErrorMessage("Veuillez renseigner ce champs")
      }else{
        setErrorMessage("")
      }
    }

    if(type === "email" && require){
      if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(valeur)){
        setErrorMessage("Veuillez renseigner une adresse email valide")
      }else{
        setErrorMessage("")
      }
    }

    if(type === "username" && require){
      if(!/^[a-zA-Z0-9_]+$/.test(valeur)){
        setErrorMessage("Veuillez renseigner un nom d'utilisateur valide")
      }else {
        setErrorMessage("")
      }
    }

  }
  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    changeValue(e.target.value)
    let valeur = e.target.value
    if(require){
      if(!valeur){
        setErrorMessage("Veuillez renseigner ce champs")
      }else{
        setErrorMessage("")
      }
    }

  }

  useEffect(() => {
    switch (type){
      case "username":
        setTypeField("text")
        break;
      default:
        setTypeField(type)
        break;
    }
  }, [type])

  useEffect(() => {
    if(error){
      if(id === error.field){
        setErrorMessage(error.message)
      }
    }
  }, [error, id])

  return (
    <div className={`kbn-textfield ${type === "password" ? "is--password" : ""} ${errorMessage ? "st-error" : (isFocused ? "ev-focused" : "")}`}>
      <label className={"kbn-textfield__label kbn-textfield-label"} htmlFor={`kb-normal-input-` + id}>
        <strong className={"kbn-textfield-label__text"}>{label}</strong>
        {
          !require && (
            <span className={"kbn-textfield__optionnal"}>(optionnel)</span>
          )
        }
      </label>
      <div className={"kbn-textfield__field"}>
        {
          type === "textarea" ? (
            <textarea  id={`kbn-input-`+id} placeholder={placeholder} onBlur={() => {setIsFocused(false)}}  onFocus={() => {setIsFocused(true)}} value={value} onChange={handleTextareaChange} required={require} />
          ) : (
            type === "number" ? (
              <input type={typeField} id={`kbn-input-`+id} placeholder={placeholder}  onBlur={() => {setIsFocused(false)}}  onFocus={() => {setIsFocused(true)}} value={value} onChange={handleChange} required={require} min={min || -99999999} max={max || 99999999} />
            ) : (
              <input type={typeField} id={`kbn-input-`+id} placeholder={placeholder} onBlur={() => {setIsFocused(false)}}  onFocus={() => {setIsFocused(true)}} value={value} onChange={handleChange} required={require} />
            )
          )
        }
        {
          type === "password" ? (
            <button type={"button"} className={`kbn-textfield__btn`} onClick={() => {
              if(typeField === "password") {
                setTypeField("text")
              } else{
                setTypeField("password")
              }
            }}>
              {
                typeField === "password" ? (
                  <i className="icon-eye-alt"></i>
                ) : <i className="icon-eye-slash-alt"></i>
              }
            </button>
          ) : null
        }
      </div>
      {
        errorMessage ? (
          <div className={"kbn-textfield__message kbn-textfield-message"}>
            {errorMessage}
          </div>
        ) : null
      }
    </div>
  );
};
