import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import auth from "../controller/auth/auth.slice"
import user from "../controller/user/user.slice"
import category from "../controller/category/category.slice"
import product from "../controller/product/product.slice"

export const store = configureStore({
    reducer: {
        auth,
        category,
        user,
        product
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AddDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AddDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;