import { useContext, useEffect } from "react";
import { UserAuthContext } from "../../../../context/AuthContext";
import { AnnoyedCuate } from "../../../assets/illustration"
import { MenuLayout } from "../../../layout"
import { links } from "../../../routes";
import { useNavigate } from "react-router-dom";

export const ErrorPage = () => {
    const { token } = useContext(UserAuthContext);
    let navigate = useNavigate()

    useEffect(() => {
      if(!token){
        navigate(links.login + `?url=${links.error}`)
      }
    }, [token, navigate]);

    return (
      <MenuLayout isMin={true} title='Page introuvable'>
        <div className='kbn-page'>
          <div className='kbn-page-arianne'>
            <p className='active'>
              <span>Error</span>
            </p>
          </div>
          <div className='kbn-page-title'>
            <h1>Oupss</h1>
          </div>
          <div className={"kbn-empty-status"}>
            <div className='kbn-empty-status__illu'>
              <AnnoyedCuate/>
            </div>
            <div className='kbn-empty-status__container'>
              <strong>{"Page indisponible ou introuvable "}</strong>
            </div>
          </div>
        </div>
      </MenuLayout>
    )
  }
  