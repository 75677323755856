import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { APIURL, deleteProtecteItems, getProtecteItems, setProtecteItems, updateProtecteItems } from '../../utils';
import { UserState } from '../../interface/GeneralStateInterface';


const URI = APIURL+"/users"
const AURI = APIURL+"/admin/users"
const OURI = APIURL+"/owner/users"

const initialState: UserState = {
    users: [],
    users_info: null,
    user: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    isCreateError: false,
    isCreateSuccess: false,
    isCreateLoading: false,

    isEditError: false,
    isEditSuccess: false,
    isEditLoading: false,

    isDeleteError: false,
    isDeleteSuccess: false,
    isDeleteLoading: false,

    message: null,

    isPasswordError: false,
    isPasswordLoading: false,
    isPasswordSuccess: false
}

export const setUser = createAsyncThunk<any, Object>(
    'user/create',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${OURI}/register/agent`,
                content,
                token: tokens
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)
export const getUsers = createAsyncThunk<any, Object>(
    'user/list',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
            // console.log
            let data = {
                url: `${AURI}/list?page=${content?.page}&size=${content?.size}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const getUser = createAsyncThunk<any, Object>(
    'user/show',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${AURI}?userId=${content}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const deleteUser = createAsyncThunk<any, Object>(
    'user/delete',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${AURI}?userId=${content}`,
                token: tokens,
                content
            }
            console.log(data)
            return await deleteProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const updateUser = createAsyncThunk<any, Object>(
    'user/update',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${OURI}'`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        reset: (state: any) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false

            state.isEditError = false
            state.isEditLoading = false
            state.isEditSuccess = false


            state.isDeleteError = false
            state.isDeleteLoading = false
            state.isDeleteSuccess = false

            state.isCreateLoading = false
            state.isCreateError = false
            state.isCreateSuccess = false

            state.message = null
        },
    },
    extraReducers: (builder) => {
        builder
        
        .addCase(getUsers.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getUsers.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.users_info = action.payload
            state.users = action.payload?.content
        })
        .addCase(getUsers.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(getUser.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getUser.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.user = action.payload
        })
        .addCase(getUser.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.user = null
        })

        .addCase(updateUser.pending, (state) => {
            state.isEditLoading = true
        })
        .addCase(updateUser.fulfilled, (state, action) => {
            state.isEditLoading = false
            state.isEditSuccess = true
            state.user = action.payload
        })
        .addCase(updateUser.rejected, (state, action) => {
            state.isEditLoading = false
            state.isEditError = true
            state.message = action.payload
            state.user = null
        })

        .addCase(setUser.pending, (state) => {
            state.isCreateLoading = true
        })
        .addCase(setUser.fulfilled, (state, action) => {
            state.isCreateLoading = false
            state.isCreateSuccess = true
            state.users = [action.payload, ...state.users]
            state.user = action.payload
        })
        .addCase(setUser.rejected, (state, action) => {
            state.isCreateLoading = false
            state.isCreateError = true
            state.message = action.payload
            state.user = null
        })

        .addCase(deleteUser.pending, (state) => {
            state.isDeleteLoading = true
        })
        .addCase(deleteUser.fulfilled, (state, action) => {
            state.isDeleteLoading = false
            state.isDeleteSuccess = true
            state.user = action.payload
            state.users = state.users.filter((admin: any) => admin?.id !== action.payload?.id)
        })
        .addCase(deleteUser.rejected, (state, action) => {
            state.isDeleteLoading = false
            state.isDeleteError = true
            state.message = action.payload
            state.user = null
        })

    },
})

export const { reset } = userSlice.actions
export default userSlice.reducer