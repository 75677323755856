import React, { useContext, useEffect } from 'react'
import { MenuLayout } from '../../../../layout'
import { AloneCuate } from '../../../../assets/illustration'
import { Link, useNavigate } from 'react-router-dom'
import { links } from '../../../../routes'
import { Button } from '../../../../components'
import { UserAuthContext } from '../../../../../context/AuthContext'
import { capitalize } from '../../../../utils/functions'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale';

export const AccountPage = () => {
  const { token, info, logOut } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.account}`)
    }
  }, [token, navigate]);

  return (
    <MenuLayout title={"Mon compte"} isMin={true}>
      <div className='kbn-page'>
        <div className='kbn-page-arianne'>
          <Link to={links.home}>
            <span>Accueil</span>
          </Link>
          <span>/</span>
          <p className='active'>
            <span>Mon compte</span>
          </p>
        </div>
        <div className='kbn-page-title'>
          <h1>Mon compte</h1>
        </div>
        
        <div className='kbn-page-account'>
          <div className='kbn-page-account__information kbn-page-account-information'>
              <div className='kbn-page-account-information__avatar'>
                {
                  info?.avatar ? (
                    <img src={info?.avatar} alt="avatar"/>
                  ) : (
                    <i className="icon-user"></i>
                  )
                }
              </div>
              <div className='kbn-page-account-information__name'>
                <h2>{info?.firstName + " " + info?.lastName?.toUpperCase()}</h2>
                <span>Compte créé le {info?.created_at ? capitalize(format(new Date(info?.created_at), "dd/MM/yyyy à HH:mm")) : "ND"}</span>
              </div>
              <div className='kbn-page-account-information__actions'>
                <div className='kbn-page-account-information__action'>
                  <Button btnType={"button"} label={"Modifier mon compte"} btnStyle={"is--neutral"} active={true} withIconLeft={true} iconLeftClass={"icon-user-pen"} onClick={() => navigate(links.account_info)}/>
                </div>
                <div className='kbn-page-account-information__action'>
                  <Button btnType={"button"} label={"Se deconnecter"} btnStyle={"is--danger"} active={true} withIconLeft={false} onClick={() => logOut()}/>
                </div>
              </div>
              {/* <p>Compte créé le {info?.createdAt ? capitalize(format(new Date(info?.createdAt), "EEEE d MMMM yyyy à HH:mm:ss", {locale: fr})) : "ND"}</p> */}
          </div>
          <div className='kbn-page-account__container'>
            <div className='kbn-page-account__grid'>
              <div className='kbn-page-account__box kbn-page-account-box is--full'>
                  <h2>Identité et contacts</h2>
                  <div className='kbn-page-account-box__container'>
                    <div className='kbn-page-account-box__item'>
                      <span>Nom:</span>
                      <strong>{info?.lastName?.toUpperCase()}</strong>
                    </div>
                    <div className='kbn-page-account-box__item'>
                      <span>Prénom(s):</span>
                      <strong>{info?.firstName}</strong>
                    </div>
                    <div className='kbn-page-account-box__item'>
                      <span>Téléphone:</span>
                      <strong>{info?.phoneNumber}</strong>
                    </div>
                    <div className='kbn-page-account-box__item'>
                      <span>Email:</span>
                      <strong>{info?.email || "ND"}</strong>
                    </div>
                    <div className='kbn-page-account-box__item'>
                      <span>Genre:</span>
                      <strong>{info?.gender || "ND"}</strong>
                    </div>
                    <div className='kbn-page-account-box__item'>
                      <span>Date de naissance:</span>
                      <strong>{info?.birthday ? capitalize(format(new Date(info?.birthday), "EEEE d MMMM yyyy", {locale: fr})) : "ND"}</strong>
                    </div>
                    <div className='kbn-page-account-box__item'>
                      <span>Rôle:</span>
                      <strong className='kbn-tag'>{info.role}</strong>
                    </div>
                    <div className='kbn-page-account-box__item'>
                      <span>Statut:</span>
                      <strong className='kbn-tag'>{info.status}</strong>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MenuLayout>
  )
}
