import React, { useContext, useEffect } from 'react'
import { MenuLayout } from '../../../../../layout'
import { links } from '../../../../../routes'
import { Link, useNavigate } from 'react-router-dom'
import { ThemeContext } from '../../../../../../context/ThemeContext'
import { SettingMenu } from '../module/settingMenu'
import { UserAuthContext } from '../../../../../../context/AuthContext'

export const CustomPage = () => {
  const { token } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.account_custom}`)
    }
  }, [token, navigate]);

  const {
      theme,
      defaultDark,
      defaultTheme,
      swichtDefautTheme,
      switchTheme,
      colorPalette,
      switchPaletteColor
  } = useContext(ThemeContext);

  let schemes =  [
    {
        id: "default",
        color: "is--default"
    },
    {
        id: "palette2",
        color: "is--palette2"
    },
    {
        id: "palette3",
        color: "is--palette3"
    },
    {
        id: "palette4",
        color: "is--palette4"
    },
    {
        id: "palette5",
        color: "is--palette5"
    },
  ]
  return (
    <MenuLayout title="Customisation" isMin={true}>
     
     <div className='kbn-page is--grid'>
        <div className='kbn-page__side'>
          <SettingMenu here='custom'/>
        </div>
        <div className='kbn-page__container'>

          <div className='kbn-page-arianne'>
            <Link to={links.home}>
              <span>Accueil</span>
            </Link>
            <span>/</span>
            <Link to={links.account}>
              <span>Mon compte</span>
            </Link>
            <span>/</span>
            <p className='active'>
              <span>Customisation</span>
            </p>
          </div>

          <div className='kbn-page-title'>
            <h1>Customisation</h1>
          </div>

          <div className='kbn-page-settings'>
          
            <div className='kbn-page-setting'>
              <h2>Schema de couleurs</h2>
              <div className='kbn-page-setting__colors'>
                {
                  schemes.map((item: any, index: number) => (
                    <button key={index} className={`kbn-page-setting-color ${item.id === colorPalette ? "active" : ""}`} onClick={() => switchPaletteColor(item.id)}>
                      <div className={`kbn-page-setting-color__container ${item.color}`}></div>
                    </button>
                  ))
                }
              </div>
            </div>
            <div className='kbn-page-setting'>
              <h2>Thème : {defaultTheme === "ok" ? (defaultDark ? "Il fait nuit" : "Il fait jour") : (theme === "dark" ? "Thème Sombre" : "Thème Clair")}</h2>
              <div className={"kbn-page-setting__theme kbn-page-setting-theme"}>
                  <button type={"button"} className={"kbn-page-setting-theme-switch"} onClick={switchTheme}>
                      <div className={`kbn-page-setting-theme-switch__content ${defaultTheme === "ok" ? (defaultDark ? "is--active" : "") : (theme === "dark" ? "is--active" : "")}`}>
                        {/* <i className={defaultTheme === "ok" ? (defaultDark ? "kbn-icon-moon" : "icon-weather-sun-1") : (theme === "dark" ? "kbn-icon-moon" : "icon-weather-sun-1")}></i> */}
                      </div>
                  </button>
                  <button type={"button"} className={"kbn-page-settingn-theme-default"} onClick={swichtDefautTheme}>
                      <span>System thème</span>
                  </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </MenuLayout>
  )
}
