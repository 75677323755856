import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from './context/ThemeContext';
import { WebRouter } from './views/routes';
import { AuthContext } from './context/AuthContext';

function App() {
  const {
    theme,
    colorPalette,
    defaultDark,
    defaultTheme,
  } = useContext(ThemeContext);
  const [isConnected, setIsConnected] = useState<boolean>(true)
  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsConnected(window.navigator.onLine);
    }, 2000); 

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <AuthContext>
      <div 
        className={"kbn-app"}
        data-theme={defaultTheme === "ok" ? (defaultDark ? "dark" : "light") : theme}
        data-palette={colorPalette}
      >
        <WebRouter/>

        {
          !isConnected ? (
            <div className={`kbn-offline-alert`}>
              <p><i className="icon-wifi-exclamation"></i> <strong>Vous n'êtes pas connecté à internet!!</strong></p>
            </div>
          ) : null
        }
      </div>
    </AuthContext>
  );
}

export default App;
