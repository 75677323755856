import { Link, useNavigate, useParams } from "react-router-dom";

import { useContext, useEffect, useState } from "react";
import { UserAuthContext } from "../../../../../context/AuthContext";
import { links } from "../../../../routes";
import { MenuLayout } from "../../../../layout";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { getUser, reset } from "../../../../../app/controller/user/user.slice";
import { capitalize } from "../../../../utils/functions";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { MeditationCuate } from "../../../../assets/illustration";

export const UserPage = () => {
    const {slug} = useParams()
    const { token } = useContext(UserAuthContext);
    let navigate = useNavigate()

    useEffect(() => {
      if(!token){
        navigate(links.login + `?url=${links.user + slug}`)
      }
    }, [token, slug, navigate]);
    const dispatch = useAppDispatch();
    const { isLoading, isSuccess, isError, user, message } = useAppSelector((state) => state.user);
    const [passwordModal, setPasswordModal] = useState<boolean>(false)
    const [roleModal, setRoleModal] = useState<boolean>(false)
    
    useEffect(() => {
      if(slug){
        dispatch(getUser(slug))
      }
      return () => {
        dispatch(reset())
      }
    }, [dispatch, slug]);

    return (
      <MenuLayout title="Client" isMin={true} here="client">
        <div className='kbn-page'>
          <div className='kbn-page-arianne'>
            <Link to={links.home}>
              <span>Accueil</span>
            </Link>
            <span>/</span>
            <Link to={links.users}>
              <span>Clients</span>
            </Link>
            <span>/</span>
            <p className='active'>
              <span>Profile Client: {isLoading ? "Chargement" : isSuccess && user ? (user?.firstName + " " + user?.lastName?.toUpperCase()) : "ND"}</span>
            </p>
          </div>
          {
            isLoading ? (
              <div className={"kbn-loader2"}>
                <i className="kbn-icon-spin6"/>
              </div>
            ) : (
              isError? (
                <div className={"kbn-empty-status"}>
                  <div className='kbn-empty-status__illu'>
                    <MeditationCuate/>
                  </div>
                  <div className='kbn-empty-status__container'>
                    <strong>Oupss!! Une erreur est survenue</strong>
                    {message}
                    <button type='button' onClick={() => window.location.reload()} style={{textDecoration: "underline"}}>Reactualiser...</button>
                  </div>
                </div>
              ) : (
                <>
                  <div className='kbn-page-title'>
                    <h1>Profile client</h1>
                  </div>
                  <div className='kbn-page-account'>
                    <div className='kbn-page-account__information kbn-page-account-information'>
                      <div className='kbn-page-account-information__avatar'>
                        {
                          user?.avatar ? (
                            <img src={user?.avatar} alt="avatar"/>
                          ) : (
                            <i className="icon-user"></i>
                          )
                        }
                      </div>
                      <div className='kbn-page-account-information__name'>
                        <h2>{user?.firstName + " " + user?.lastName?.toUpperCase()}</h2>
                        <span>Compte créé le {user?.created_at ? capitalize(format(new Date(user?.created_at), "dd/MM/yyyy à HH:mm")) : "ND"}</span>
                      </div>
                      <div className='kbn-page-account-information__actions'>
                        
                      </div>
                    </div>
                    <div className='kbn-page-account__container'>
                      <div className='kbn-page-account__grid'>
                        <div className='kbn-page-account__box kbn-page-account-box is--full'>
                            <h2>Identité et contacts</h2>
                            <div className='kbn-page-account-box__container'>
                              <div className='kbn-page-account-box__item'>
                                <span>Nom:</span>
                                <strong>{user?.lastName?.toUpperCase()}</strong>
                              </div>
                              <div className='kbn-page-account-box__item'>
                                <span>Prénom(s):</span>
                                <strong>{user?.firstName}</strong>
                              </div>
                              <div className='kbn-page-account-box__item'>
                                <span>Téléphone:</span>
                                <strong>{user?.phoneNumber || "ND"}</strong>
                              </div>
                              <div className='kbn-page-account-box__item'>
                                <span>Email:</span>
                                <strong>{user?.email || "ND"}</strong>
                              </div>
                              <div className='kbn-page-account-box__item'>
                                <span>Genre:</span>
                                <strong>{user?.gender || "ND"}</strong>
                              </div>
                              <div className='kbn-page-account-box__item'>
                                <span>Date de naissance:</span>
                                <strong>{user?.birthday ? capitalize(format(new Date(user?.birthday), "EEEE d MMMM yyyy", {locale: fr})) : "ND"}</strong>
                              </div>
                              {/* <div className='kbn-page-account-box__item'>
                                <span>Rôle:</span>
                                <strong className='kbn-tag'>{user.role}</strong>
                              </div> */}
                              <div className='kbn-page-account-box__item'>
                                <span>Statut:</span>
                                <strong className='kbn-tag'>{user.status}</strong>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            )
          }
        </div>
      </MenuLayout>
    )
  }
  