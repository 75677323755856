import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { APIURL, deleteProtecteItems, getProtecteItems, setProtecteItems, updateProtecteItems } from '../../utils';
import { CategoryState } from '../../interface/GeneralStateInterface';
import axios from 'axios';

const URI = APIURL+"/product/category"
const URIA = APIURL+"/admin/product/category"

const initialState: CategoryState = {
    categories: [],
    category: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    isCreateError: false,
    isCreateSuccess: false,
    isCreateLoading: false,

    isEditError: false,
    isEditSuccess: false,
    isEditLoading: false,

    isDeleteError: false,
    isDeleteSuccess: false,
    isDeleteLoading: false,

    message: null,
    categories_info: null
}

export const setCategory = createAsyncThunk<any, Object>(
    'category/create',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URIA}`,
                content,
                token: tokens
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const importCategoryFromCsv = createAsyncThunk<any, Object>(
    'category/import-from-csv',
    async (content: any, thunkAPI) => {
        try {
            
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
            
            const response = await axios.post(`${URIA}/csv`, content, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${tokens}`,
                },
            });

            return response.data
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const getCategories = createAsyncThunk<any, Object>(
    'category/list',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}/list?pageNumber=${content?.page}&limit=${content?.size}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const getCategory = createAsyncThunk<any, Object>(
    'category/show',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}?id=${content}'`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const updateCategory = createAsyncThunk<any, Object>(
    'category/update',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URIA}`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const deleteCategory = createAsyncThunk<any, Object>(
    'category/delete',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URIA}?id=${content}`,
                token: tokens,
                content
            }
            return await deleteProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        reset: (state: any) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false

            state.isEditError = false
            state.isEditLoading = false
            state.isEditSuccess = false

            state.isImportLoading = false
            state.isImportSuccess = false
            state.isImportError = false

            state.isDeleteError = false
            state.isDeleteLoading = false
            state.isDeleteSuccess = false

            state.isCreateLoading = false
            state.isCreateError = false
            state.isCreateSuccess = false

            state.message = null
        },
    },
    extraReducers: (builder) => {
        builder
        
        .addCase(getCategories.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getCategories.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.categories_info = action.payload
            state.categories = action.payload?.content
        })
        .addCase(getCategories.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(getCategory.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getCategory.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.category = action.payload
        })
        .addCase(getCategory.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.category = null
        })

        .addCase(updateCategory.pending, (state) => {
            state.isEditLoading = true
        })
        .addCase(updateCategory.fulfilled, (state, action) => {
            state.isEditLoading = false
            state.isEditSuccess = true
            state.category = action.payload
        })
        .addCase(updateCategory.rejected, (state, action) => {
            state.isEditLoading = false
            state.isEditError = true
            state.message = action.payload
            state.category = null
        })

        .addCase(setCategory.pending, (state) => {
            state.isCreateLoading = true
        })
        .addCase(setCategory.fulfilled, (state, action) => {
            state.isCreateLoading = false
            state.isCreateSuccess = true
            state.categories = [action.payload, ...state.categories]
            state.category = action.payload
        })
        .addCase(setCategory.rejected, (state, action) => {
            state.isCreateLoading = false
            state.isCreateError = true
            state.message = action.payload
            state.category = null
        })

        .addCase(importCategoryFromCsv.pending, (state) => {
            state.isImportLoading = true
        })
        .addCase(importCategoryFromCsv.fulfilled, (state, action) => {
            state.isImportLoading = false
            state.isImportSuccess = true
            state.categories = [...action.payload, ...state.categories]
            // state.category = action.payload
        })
        .addCase(importCategoryFromCsv.rejected, (state, action) => {
            state.isImportLoading = false
            state.isImportError = true
            state.message = action.payload
            // state.category = null
        })

        .addCase(deleteCategory.pending, (state) => {
            state.isDeleteLoading = true
        })
        .addCase(deleteCategory.fulfilled, (state, action) => {
            state.isDeleteLoading = false
            state.isDeleteSuccess = true
            state.category = action.payload
            state.categories = state.categories?.filter((el: any) => el.id !== action.payload?.id)
        })
        .addCase(deleteCategory.rejected, (state, action) => {
            state.isDeleteLoading = false
            state.isDeleteError = true
            state.message = action.payload
            state.category = null
        })
    },
})

export const { reset } = categorySlice.actions
export default categorySlice.reducer