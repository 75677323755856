import React, {createContext, useEffect, useState} from 'react';
import { useAppDispatch, useAppSelector } from '../app/store';
import { getMe, logout } from '../app/controller/auth/auth.slice';



export const UserAuthContext = createContext<null | any>(null);

interface AuthContextParams {
    children?: React.ReactNode
}


export const AuthContext: React.FC<AuthContextParams> = ({children}) => {
    const [info, setInfo] = useState<any | null>(null)
    const [redirectTo, setRedirectTo] = useState<any | null>(null)

    const dispatch = useAppDispatch();
    const {token, me, isLoading, isError, message} = useAppSelector((state) => state.auth);

    useEffect(() => {
        if(token){
            dispatch(getMe({}))
        }

    }, [dispatch, token])

    useEffect(() => {
        setInfo(me)
    }, [me])


    const logOut = () => {
       
        dispatch(logout({}))
        window.location.reload()
    }

    useEffect(() => {
        if(isError){
            dispatch(logout({}))
        }
    }, [dispatch, isError]);

    return (
        <UserAuthContext.Provider value={{
            info,
            token,
            setInfo,
            logOut,
            isLoading,
            isError,
            message,
            redirectTo, 
            setRedirectTo,
        }}>
            {children}
        </UserAuthContext.Provider>
    );
};
