import { Link, useNavigate } from "react-router-dom";
import QuillEditor from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import { UserAuthContext } from "../../../../../context/AuthContext";
import { links } from "../../../../routes";
import { MenuLayout } from "../../../../layout";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { getCategories, reset } from "../../../../../app/controller/category/category.slice";
import { setProduct, reset as preset } from "../../../../../app/controller/product/product.slice";
import { MeditationCuate } from "../../../../assets/illustration";
import { AlertModule, Button, ModalLayout, Textfield } from "../../../../components";
import { slugifyFileName } from "../../../../utils/functions";
import axios from "axios";

export const Categoriefield = ({subcategoryId, require, error, fnc} : {subcategoryId?: string, require?: boolean, error?: any, fnc: (val?: string | any) => void}) => {
  const [categoryId, setCategoryId] = useState<any>(null);
  const {categories}: any = useAppSelector((state) => state.category);

  const [errorMessage, setErrorMessage] = useState<string>("")
  const [search, setSearch] = useState<string>("")
  const [searchS, setSearchS] = useState<string>("")
  const [openOptions, setOptions] = useState<boolean>(false)

  useEffect(() => {
    if(error){
      if("categoryId" === error.field){
        setErrorMessage(error.message)
      }
    }
  }, [error])

  useEffect(() => {
    if(subcategoryId && categories){
      setCategoryId(categories.filter((el: any) => el.id === subcategoryId)[0]?.parent)
    }
  }, [categories, subcategoryId])

  return (
    <div className={`kbn-selectfield ${errorMessage ? "st-error" : ""}`}>
      <label className={"kbn-selectfield__label kbn-selectfield-label"} htmlFor={`kbn-input-subcategoryId`}>
        <strong className={"kbn-selectfield-label__text"}>Sous-categorie</strong>
        <div className={"kbn-selectfield__tooltip kbn-selectfield-tooltip"}>
          <i className=" icon-question-square"></i>
          <div className={"kbn-selectfield-tooltip__text"}>
            <strong>This field is {require ? "obligatory" : "optional"}</strong>
            {/* <span>{tooltip}</span> */}
          </div>
        </div>
      </label>

      <div className={"kbn-selectfield-field"}>
          <button type={"button"} className={"kbn-selectfield-field__container"} onClick={() => setOptions(!openOptions)}>
            {
              subcategoryId ? (
                <strong>{categories.filter((el: any) => el.id === subcategoryId)[0]?.label}</strong>
              ) : (
                <span>{"Choissir une sous-catégorie"}</span>
              )
            }
          </button>
          <div className={`kbn-selectfield-field-options ${openOptions ? "is--active" : ""}`}>
            {
              categoryId ? (
                <>
                  <button type='button' className='kbn-back-button' onClick={() => {setCategoryId(null); setSearchS("")}}>Revenir en arrière</button>
                  <strong>Categorie: {categoryId?.label}</strong>
                  <div className={"kbn-selectfield-field-options__search"}>
                    <input type="search" placeholder={"search..."} value={searchS} onChange={(e) => setSearchS(e.target.value)}/>
                  </div>
                  <div className={"kbn-selectfield-field-options__list"}>
                    {
                      categories.filter((el: any) => el.parent?.id === categoryId?.id).length > 0 ? (
                        categories.filter((el: any) => el.parent?.id === categoryId?.id).map((item: any, index: number) => (
                          <div className={`kbn-selectfield-field-option ${item.id === subcategoryId ? "is--active" : ""}`} key={index}>
                            <button className={"kbn-selectfield-field-option-text"} type={"button"} onClick={() => {
                              fnc(item?.id)
                              setOptions(false)
                            }}>
                              {
                                item.id === subcategoryId ? (
                                  <i className="icon-validation-check-circle"></i>
                                ) : null
                              }

                              <span>{item.label}</span>
                            </button>
                            {
                              item.id === subcategoryId ? (
                                <button className={"kbn-selectfield-field-option-delete"} onClick={() => {
                                  fnc("")
                                }}>
                                  <i className="icon-trash"></i>
                                </button>
                              ) : null
                            }
                          </div>
                        ))
                      ) : (
                        <span>No items found...</span>
                      )
                    }
                  </div>
                </>
              ) : (
                <>
                  <div className={"kbn-selectfield-field-options__search"}>
                    <input type="search" placeholder={"search..."} value={search} onChange={(e) => setSearch(e.target.value)}/>
                  </div>
                  <div className={"kbn-selectfield-field-options__list"}>
                    {
                      categories.filter((el: any) => el.parent === null).length > 0 ? (
                        categories.filter((el: any) => el.parent === null).map((item: any, index: number) => (
                          <div className={`kbn-selectfield-field-option ${item.id === categoryId?.id ? "is--active" : ""}`} key={index}>
                            <button className={"kbn-selectfield-field-option-text"} type={"button"} onClick={() => {
                              setCategoryId(item)
                              // setOptions(false)
                            }}>
                              {
                                item.id === categoryId?.id ? (
                                  <i className="icon-validation-check-circle"></i>
                                ) : null
                              }

                              <span>{item.label}</span>
                            </button>
                            {
                              item.id === categoryId?.id ? (
                                <button className={"kbn-selectfield-field-option-delete"} onClick={() => {
                                  setCategoryId(null)
                                }}>
                                  <i className="icon-trash"></i>
                                </button>
                              ) : null
                            }
                          </div>
                        ))
                      ) : (
                        <span>No items found...</span>
                      )
                    }
                  </div>
                </>
              )
            }
          </div>
        </div>
        {
          !require && (
            <span className={"kbn-selectfield__optionnal"}>(optional)</span>
          )
        }

      {
        errorMessage ? (
          <div className={"kbn-selectfield__message kbn-selectfield-message"}>
            {errorMessage}
          </div>
        ) : null
      }
    </div>
  )

}

export const DescriptionField = ({description, require, error, fnc} : {description?: string, require?: boolean, error?: any, fnc: (val?: string | any) => void}) => {
  const [errorMessage, setErrorMessage] = useState<string>("")
  useEffect(() => {
    if(error){
      if("descripition" === error.field){
        setErrorMessage(error.message)
      }
    }
  }, [error])

  return (
    <div className={`kbn-textfield ${errorMessage ? "st-error" : ""}`}>
      <label className={"kbn-textfield__label kbn-textfield-label"} htmlFor={`kb-normal-input-description`}>
        <strong className={"kbn-textfield-label__text"}>{"Description"}</strong>
        {
          !require && (
            <span className={"kbn-textfield__optionnal"}>(optionnel)</span>
          )
        }
      </label>
        <QuillEditor
          className={"kbn-editor"}
          theme="snow"
          value={description}
          onChange={(value) => fnc(value)}
        />
      {
        errorMessage ? (
          <div className={"kbn-textfield__message kbn-textfield-message"}>
            {errorMessage}
          </div>
        ) : null
      }
    </div>
  )
}

export const ProductCreatePage = () => {
    const { token } = useContext(UserAuthContext);
    let navigate = useNavigate()

    useEffect(() => {
      if(!token){
        navigate(links.login + `?url=${links.product_create}`)
      }
    }, [token, navigate]);


    const { isLoading, isError, message } = useAppSelector((state) => state.category);
    const {isCreateError, isCreateSuccess, product, message: aMessage} = useAppSelector((state) => state.product);
    const dispatch = useAppDispatch();
    const [error, setError] = useState<any>(null)
    
    const [description, setDescription] = useState<any>("");
    
    const [cover, setCovers] = useState<any>(null);
    const [coverUrl, setCoverUrl] = useState<any>(null);
    const [coverPreview, setCoverPreview] = useState<any>(null);

    const [images, setImages] = useState<any[]>([]);
    const [imagesUrl, setImagesUrl] = useState<any[]>([]);
    const [imagesPreview, setImagesPreview] = useState<any[]>([]);
    
    const [videos, setVideos] = useState<any[]>([]);
    const [videosUrl, setVideosUrl] = useState<any[]>([]);
    const [videosPreview, setVideosPreview] = useState<any[]>([]);

    const [openC, setOpenC] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    
    const [data, setData] = useState<any>({
      label: "", 
      minPrice: 0,
      maxPrice: 0,
      description: "", 
      categoryId: "",
      supplier: "",
      quantity: 0
    });

    const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) =>{
      const files = e.target.files
     
      if(files && files.length > 0){
        const file = files[0]
        let type: number = file.size
        let max: number = 1024 * 1024 * 4
        if(type < max){
            let info = {
              name: slugifyFileName(file.name),
              size: file.size,
              type: file.type
            }
            if(imagesUrl.filter((el: any) => el === info.name).length < 1){
              setCoverUrl(info.name)
              setCovers({name: info.name, file: file, progress: 0, status: "normal"})
              setCoverPreview(URL.createObjectURL(file))
            }

        }else{
          setError({
              field: "global",
              message: "Votre fichier ne doit pas dépasser 4Mo"
          })
          return
        }
    
        setError(null)
          
      }
    }

    const handleMultiChangeFile = (e: ChangeEvent<HTMLInputElement>) =>{
      const file = e.target.files
      if(file && file.length > 0){
         let tabs: File[] = Object.values(file)
          if(tabs.length < 7){
              tabs?.forEach((el) => {
                  let type: number = el.size
                  let max: number = 1024 * 1024 * 4
                  if(type < max){
                      let info = {
                        name: slugifyFileName(el.name),
                        size: el.size,
                        type: el.type
                      }
                      if(imagesUrl.filter((el: any) => el === info.name).length < 1){
                        setImagesUrl((list) => [...list, info.name])
                        setImages((list) => [...list, {name: slugifyFileName(el.name), file: el, progress: 0, status: "normal"}])
                        setImagesPreview(list => [...list, URL.createObjectURL(el)])
                      }

                  }else{
                    setError({
                        field: "global",
                        message: "Votre fichier ne doit pas dépasser 4Mo"
                    })
                    return
                  }
              })
              setError({
                field: "",
                  message: ""
              })
          }else{
              setError({
                field: "multi-image",
                message: "Vous ne devez pas dépasser 6 images"
              })
          }
      }
    }
    
    const handleMultiChangeVideoFile = (e: ChangeEvent<HTMLInputElement>) =>{
      const file = e.target.files
      if(file && file.length > 0){
         let tabs: File[] = Object.values(file)
          if(tabs.length < 4){
              tabs?.forEach((el) => {
                  let type: number = el.size
                  let max: number = 1024 * 1024 * 512
                  if(type < max){
                      let info = {
                        name: slugifyFileName(el.name),
                        size: el.size,
                        type: el.type
                      }
                      if(videosUrl.filter((el: any) => el === info.name).length < 1){
                        setVideosUrl((list) => [...list, info.name])
                        setVideos((list) => [...list, {name: info.name, file: el, progress: 0, status: "normal"}])
                        setVideosPreview(list => [...list, URL.createObjectURL(el)])
                      }
                  }else{
                    setError({
                      field: "global",
                      message: "Votre fichier ne doit pas dépasser 512Mo"
                    })
                    return
                  }
              })
              setError({
                field: "",
                  message: ""
              })
          }else{
              setError({
                field: "multi-video",
                  message: "Vous ne devez pas dépasser 3 videos"
              })
          }
      }
    }
    
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if(!data.label){
          setError({
              field: "label",
              message: "Veuillez renseigner le titre"
          })
          return
      }
      if(!description){
          setError({
            field: "description",
            message: "Veuillez renseigner la description"
          })
          return
      }
      if(!data.categoryId){
          setError({
            field: "categoryId",
              message: "Veuillez renseigner la sous-categorie"
          })
          return
      }
      if(!data.minPrice){
          setError({
            field: "minPrice",
              message: "Veuillez entrer le prix minimum"
          })
          return
      }
      if(!data.maxPrice){
          setError({
            field: "maxPrice",
              message: "Veuillez entrer le prix maximum"
          })
          return
      }
      if(!data.supplier){
          setError({
            field: "supplier",
              message: "Veuillez entrer le fournisseurs"
          })
          return
      }
      if(!data.quantity){
          setError({
            field: "quantity",
              message: "Veuillez entrer le fournisseurs"
          })
          return
      }
      // if(data.maxPrice < data.minPrice){
      //     setError({
      //       field: "global",
      //       message: "Le prix minimum doit etre inferieur au prix maximum "
      //     })
      //     return
      // }
      if(!cover){
          setError({
            field: "global",
              message: "Veuillez renseigner la photo de couverture"
          })
          return
      }
      // if(imagesUrl.length < 1){
      //     setError({
      //       field: "global",
      //         message: "Veuillez renseigner au moins une image du produit"
      //     })
      //     return
      // }
      if(imagesUrl.length > 6){
          setError({
            field: "global",
              message: "Veuillez renseigner 6 images maximum du produit"
          })
          return
      }
      // if(videosUrl.length < 1){
      //     setError({
      //       field: "global",
      //         message: "Veuillez renseigner au moins une video du produit"
      //     })
      //     return
      // }
      if(videosUrl.length > 3){
          setError({
            field: "global",
              message: "Veuillez renseigner 4 videos maximum du produit"
          })
          return
      }
      setLoading(true)
      let datas = {
          ...data,
          description,
          images: imagesUrl,
          videos: videosUrl,
          mainImage: coverUrl
      }
      dispatch(setProduct(datas))
    }

    useEffect(() => {
      dispatch(getCategories({
        page: 0,
        size: 500,
      }))
      
      return () => {
        dispatch(reset())
      }
    }, [dispatch])

    useEffect(() => {
      if(loading && isCreateError){
        setLoading(false)
        setOpenC(false)
        setError({
          field: "global",
            message: aMessage
        })
      }
    }, [loading,isCreateError, aMessage])

    useEffect(() => {
      if(loading && isCreateSuccess && product){
        setOpenC(true)
        if(product.images && product.images.length > 0){
          product.images.forEach(async (el: any, idx: number) => {
            try{
              if(idx === 0){
                const uploadResponse = await axios.put(el, cover?.file, {
                  headers: {
                    "Content-Type": "application/png",
                  },
                  onUploadProgress: (progressEvent: any) => {
                    const percentCompleted = Math.round(
                      (progressEvent.loaded * 100) / progressEvent?.total
                    );
                    setCovers({
                      name: cover?.name,
                      file: cover?.file,
                      progress: percentCompleted
                    })
                    // console.log(`Upload Progress: ${percentCompleted}%`);
                  },
                });
               
                if (uploadResponse.status === 200) {
                  setCovers( {
                    name: cover?.name,
                    file: cover?.file,
                    progress: 100
                  })
                } else {
                  setError({
                    field: "global",
                    message: "Une erreur est survenue lors de l'enregistrement d'une image"
                  })
                  // setLoading(false)
                  setCovers({
                    name: cover?.name,
                    file: cover?.file,
                    progress: 100,
                    status: "error"
                  })
                }
              }else{
                const uploadResponse = await axios.put(el, images[idx]?.file, {
                  headers: {
                    "Content-Type": "application/png",
                  },
                  onUploadProgress: (progressEvent: any) => {
                    const percentCompleted = Math.round(
                      (progressEvent.loaded * 100) / progressEvent?.total
                    );
                    setImages((list) => [...list.filter((el: any) => el.name !== images[idx]?.name), {
                      name: images[idx]?.name,
                      file: images[idx]?.file,
                      progress: percentCompleted
                    }])
                    // console.log(`Upload Progress: ${percentCompleted}%`);
                  },
                });
               
                if (uploadResponse.status === 200) {
                  setImages((list) => [...list.filter((el: any) => el.name !== images[idx]?.name), {
                    name: images[idx]?.name,
                    file: images[idx]?.file,
                    progress: 100
                  }])
                  
                } else {
                  setError({
                    field: "global",
                    message: "Une erreur est survenue lors de l'enregistrement d'une image"
                  })
                  // setLoading(false)
                  setImages((list) => [...list.filter((el: any) => el.name !== images[idx]?.name), {
                    name: images[idx]?.name,
                    file: images[idx]?.file,
                    progress: 100,
                    status: "error"
                  }])
                }
              }
              const uploadResponse = await axios.put(el, images[idx]?.file, {
                headers: {
                  "Content-Type": "application/png",
                },
                onUploadProgress: (progressEvent: any) => {
                  const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent?.total
                  );
                  setImages((list) => [...list.filter((el: any) => el.name !== images[idx]?.name), {
                    name: images[idx]?.name,
                    file: images[idx]?.file,
                    progress: percentCompleted
                  }])
                  // console.log(`Upload Progress: ${percentCompleted}%`);
                },
              });
             
              if (uploadResponse.status === 200) {
                setImages((list) => [...list.filter((el: any) => el.name !== images[idx]?.name), {
                  name: images[idx]?.name,
                  file: images[idx]?.file,
                  progress: 100
                }])
                
              } else {
                setError({
                  field: "global",
                  message: "Une erreur est survenue lors de l'enregistrement d'une image"
                })
                // setLoading(false)
                setImages((list) => [...list.filter((el: any) => el.name !== images[idx]?.name), {
                  name: images[idx]?.name,
                  file: images[idx]?.file,
                  progress: 100,
                  status: "error"
                }])
              }

            }catch(e: any){
              console.log(e)
              setError({
                field: "global",
                message: "Une erreur est survenue lors de l'enregistrement d'une image"
              })
              setImages((list) => [...list.filter((el: any) => el.name !== images[idx]?.name), {
                name: images[idx]?.name,
                file: images[idx]?.file,
                progress: 100,
                status: "error"
              }])
            }
          });
        }
        if(product.videos && product.videos.length > 0){
          product.videos.forEach(async (el: any, idx: number) => {
            try{
              const uploadResponse = await axios.put(el, videos[idx].file, {
                headers: {
                  "Content-Type": "application/png",
                },
                onUploadProgress: (progressEvent: any) => {
                  const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent?.total
                  );
                  setVideos((list) => [...list.filter((el: any) => el.name !== videos[idx].name), {
                    name: videos[idx].name,
                    file: videos[idx].file,
                    progress: percentCompleted
                  }])
                },
              });
             
              if (uploadResponse.status === 200) {
                console.log(`ok for success video ${idx + 1}`)
                setVideos((list) => [...list.filter((el: any) => el.name !== videos[idx].name), {
                  name: videos[idx].name,
                  file: videos[idx].file,
                  progress: 100
                }])
              } else {
                setError({
                  field: "global",
                  message: "Une erreur est survenue lors de l'enregistrement d'une image"
                })
                // setLoading(false)
                
                setVideos((list) => [...list.filter((el: any) => el.name !== videos[idx].name), {
                  name: videos[idx].name,
                  file: videos[idx].file,
                  progress: 100,
                  status: "error"
                }])
            
              }

            }catch(e: any){
              setError({
                field: "global",
                message: "Une erreur est survenue lors de l'enregistrement d'une image"
              })
              setVideos((list) => [...list.filter((el: any) => el.name !== videos[idx].name), {
                name: videos[idx].name,
                file: videos[idx].file,
                progress: 100,
                status: "error"
              }])
          
              // setLoading(false)
            }
          });
        }
      }
      return () => {
        dispatch(preset())
      }
    }, [isCreateSuccess, product, loading, dispatch, images, videos, cover])

    // useEffect(() => {
    //   if((cover?.status === "normal" && cover?.progress >= 100) && (images?.filter((el: any) => el.status === "normal" && el.progess >= 100).length + videos?.filter((el: any) => el.status === "normal" &&  el.progess >= 100).length) === 0){
    //     setLoading(false)
    //   }
    // }, [videos, images, cover])

    useEffect(() => {
      // if(isCreateSuccess && product){
        console.log(cover, images, videos)
      // }
    }, [cover, images, videos])

    if(isLoading){
      return (
        <div className={"kbn-loader"}>
          <i className="icon-spin"/>
        </div>
      )
    }
    if(isError){
      <div className={"kbn-empty-status"}>
        <div className='kbn-empty-status__illu'>
          <MeditationCuate/>
        </div>
        <div className='kbn-empty-status__container'>
          <strong>Oupss!! Une erreur est survenue</strong>
          {message}
          <button type='button' onClick={() => window.location.reload()} style={{textDecoration: "underline"}}>Reactualiser...</button>
        </div>
      </div>
    }

    return (
      <MenuLayout title="Add Product" isMin={true} here="product">
        <div className='kbn-page'>
          <div className='kbn-page-arianne'>
            <Link to={links.home}>
              <span>Accueil</span>
            </Link>
            <span>/</span>
            <Link to={links.products}>
              <span>Produits</span>
            </Link>
            <span>/</span>
            <p className='active'>
              <span>Ajout de produit</span>
            </p>
          </div>
          <div className='kbn-page-title'>
            <h1>Ajout de produit</h1>
          </div>

          <div className='kbn-page__contain kbn-page-contain'>
            <form onSubmit={handleSubmit} className='kbn-page-form'>
              {
                isCreateError ? (
                    <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={aMessage}/>
                ) : (
                  error && error?.field === "global" ? (
                      <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={error?.message}/>
                  ) : null
                )
              }
              <div className='kbn-page-form__container'>
                <div className='kbn-page-form__contain'>
                  <h2>Image de couverture</h2>
                </div>
                <div className={"kbn-mulit-file"}>
                  <input type="file" accept='.jpg, .png,.jpeg' id='imageImg' onChange={handleChangeFile}/>
                  <div className='kbn-mulit-file__grid'>
                    {
                      cover ? (
                        <div className={"kbn-mulit-file-preview"}>
                            <img src={coverPreview} alt="article"/>
                            <button type="button" className={"kbn-mulit-file-preview__btn is--danger"} onClick={() => {
                                setCoverUrl("")
                                setCoverPreview(null)
                                setCovers(null)
                            }}>
                              <i className="icon-trash"></i>
                            </button>
                        </div>
                      ) : (
                        <label htmlFor="imageImg" className={"kbn-mulit-file-btn"}>
                          <i className=" icon-picture-gallery"></i>
                          {
                            error?.field === "multi-image" ? (
                              <span style={{color: "red"}}>{error?.message}</span>
                            ) : (
                              <span>Cliquer pour changer l'image de couverture du produit à vendre </span>
                            )
                          }
                        </label>
                      )
                    }
                  </div>
                </div>
              </div>
              <div className='kbn-page-form__container'>
                <div className='kbn-page-form__contain'>
                  <h2>Images</h2>
                </div>
                <div className={"kbn-mulit-file"}>
                  <input type="file" accept='.jpg, .png,.jpeg' multiple id='imagesImg' onChange={handleMultiChangeFile}/>
                  <div className='kbn-mulit-file__grid'>
                    {
                          images.length > 0 ? (
                              images.length > 5 ? (
                                  images.map((image, index) => (
                                      <div className={"kbn-mulit-file-preview"} key={index}>
                                          <img src={imagesPreview[index]} alt="article"/>
                                          <button type="button" className={"kbn-mulit-file-preview__btn is--danger"} onClick={() => {
                                              setImagesUrl(list => [...list.filter(el => el !== imagesUrl[index])])
                                              setImagesPreview(list => [...list.filter(el => el !== imagesPreview[index])])
                                              setImages(list => [...list.filter(el => el !== image)])
                                          }}>
                                              <i className="icon-trash"></i>
                                          </button>
                                      </div>
                                  ))
                              ) : (
                                  <>
                                      {
                                          images.map((image, index) => (
                                              <div className={"kbn-mulit-file-preview"} key={index}>
                                                  <img src={imagesPreview[index]} alt="projet"/>
                                                  <button type="button" className={"kbn-mulit-file-preview__btn is--danger"} onClick={() => {
                                                      setImagesUrl(list => [...list.filter(el => el !== imagesUrl[index])])
                                                      setImagesPreview(list => [...list.filter(el => el !== imagesPreview[index])])
                                                      setImages(list => [...list.filter(el => el !== image)])
                                                  }}>
                                                      <i className="icon-trash"></i>
                                                  </button>
                                              </div>
                                          ))
                                      }
                                      <label htmlFor="imagesImg" className={"kbn-mulit-file-preview_btn"}>
                                          <i className="icon-add-square"></i>
                                          <span>Vous pouvez ajouter {5 - imagesUrl.length} image(s) si vous le voulez</span>
                                      </label>
                                  </>
                              )
                          ) : (
                              <label htmlFor="imagesImg" className={"kbn-mulit-file-btn"}>
                                  <i className=" icon-picture-gallery"></i>
                                  {
                                      error?.field === "multi-image" ? (
                                          <span style={{color: "red"}}>{error?.message}</span>
                                      ) : (
                                          <span>Cliquer pour changer des images du produit à vendre (5 max - 4Mo max par image) </span>
                                      )
                                  }

                              </label>
                          )
                    }
                  </div>
                </div>
              </div>

              <div className='kbn-page-form__container'>
                <div className='kbn-page-form__contain'>
                  <h2>Videos</h2>
                </div>
                <div className={"kbn-mulit-file"}>
                  <input type="file" accept='.mp4, .mov, .avi' multiple id='videosImg' onChange={handleMultiChangeVideoFile}/>
                  <div className='kbn-mulit-file__grid'>
                    {
                          videos.length > 0 ? (
                            videos.length > 2 ? (
                                videos.map((image, index) => (
                                      <div className={"kbn-mulit-file-preview"} key={index}>
                                          <video src={videosPreview[index]} controls/>
                                                  <button type="button" className={"kbn-mulit-file-preview__btn is--danger"} onClick={() => {
                                                      setVideosUrl(list => [...list.filter(el => el !== videosUrl[index])])
                                                      setVideosPreview(list => [...list.filter(el => el !== videosPreview[index])])
                                                      setVideos(list => [...list.filter(el => el !== image)])
                                                  }}>
                                                      <i className="icon-trash"></i>
                                                  </button>
                                      </div>
                                  ))
                              ) : (
                                  <>
                                      {
                                          videos.map((image, index) => (
                                              <div className={"kbn-mulit-file-preview"} key={index}>
                                                  <video src={videosPreview[index]} controls/>
                                                  <button type="button" className={"kbn-mulit-file-preview__btn is--danger"} onClick={() => {
                                                      setVideosUrl(list => [...list.filter(el => el !== videosUrl[index])])
                                                      setVideosPreview(list => [...list.filter(el => el !== videosPreview[index])])
                                                      setVideos(list => [...list.filter(el => el !== image)])
                                                  }}>
                                                      <i className="icon-trash"></i>
                                                  </button>
                                              </div>
                                          ))
                                      }
                                      <label htmlFor="videosImg" className={"kbn-mulit-file-preview_btn"}>
                                          <i className="icon-add-square"></i>
                                          <span>Vous pouvez ajouter {3 - videosUrl.length} videos(s) si vous le voulez</span>
                                      </label>
                                  </>
                              )
                          ) : (
                              <label htmlFor="videosImg" className={"kbn-mulit-file-btn"}>
                                  <i className=" icon-picture-gallery"></i>
                                  {
                                      error?.field === "multi-video" ? (
                                          <span style={{color: "red"}}>{error?.message}</span>
                                      ) : (
                                          <span>Cliquer pour changer des videos du produit à vendre (3 max - 512Mo max par video) </span>
                                      )
                                  }

                              </label>
                          )
                      }
                  </div>
                </div>
              </div>

              <div className='kbn-page-form__container'>
                <div className='kbn-page-form__contain'>
                  <h2>Informations</h2>
                </div>
                <div className='kbn-page-form__fields'>
                  <div className='kbn-page-form__field'>
                    <Textfield
                        id={"label"}
                        value={data.label}
                        require={true}
                        changeValue={(value: any) => setData({...data, label: value})}
                        type={"text"}
                        label={"Titre"}
                        placeholder={"Meuble"}
                        error={error}
                    />
                  </div>
                  <div className='kbn-page-form__field'>
                    <Categoriefield 
                      require={true} 
                      subcategoryId={data?.categoryId} 
                      fnc={(val: string) => setData({...data, categoryId: val})} 
                      error={error}
                    />
                  </div>
                  <div className='kbn-page-form__field'>
                    <Textfield
                        id={"minPrice"}
                        value={data.minPrice}
                        require={true}
                        changeValue={(value: any) => setData({...data, minPrice: value})}
                        type={"number"}
                        label={"Prix minimun"}
                        placeholder={"20000"}
                        min={0}
                        error={error}
                    />
                  </div>
                  <div className='kbn-page-form__field'>
                    <Textfield
                        id={"maxPrice"}
                        value={data.maxPrice}
                        require={true}
                        changeValue={(value: any) => setData({...data, maxPrice: value})}
                        type={"number"}
                        label={"Prix maximun"}
                        placeholder={"25000"}
                        min={parseInt(data.minPrice) || 0}
                        error={error}
                    />
                  </div>
                  <div className='kbn-page-form__field'>
                    <Textfield
                      id={"quantity"}
                      value={data.quantity}
                      require={true}
                      changeValue={(value: any) => setData({...data, quantity: value})}
                      type={"number"}
                      label={"Quantité"}
                      placeholder={"Entrer la quantité"}
                      error={error}
                      min={0}
                    />
                  </div>
                  <div className='kbn-page-form__field'>
                    <Textfield
                      id={"supplier"}
                      value={data.supplier}
                      require={true}
                      changeValue={(value: any) => setData({...data, supplier: value})}
                      type={"text"}
                      label={"Fournisseur"}
                      placeholder={"Entrer le nom du fournisseur"}
                      error={error}
                    />
                  </div>
                </div>
              </div>

              <div className='kbn-page-form__container'>
                <div className='kbn-page-form__contain'>
                  <h2>Description</h2>
                </div>
                <div className='kbn-page-form__fields'>
                  <DescriptionField 
                    description={description} 
                    error={error} 
                    require={true} 
                    fnc={(val: any) => setDescription(val)}
                  />
                </div>
              </div>

              <div className='kbn-page-form__action'>
                  <Button 
                      btnType={'submit'} 
                      label={loading ? 'Création en cours...' : "Enregistrer et ajouter"} 
                      btnStyle={'is--primary'}
                      active={!loading}
                      withIconLeft={loading} 
                      iconLeftClass={"icon-spin"}
                  />
              </div>
            </form>

            <ModalLayout size={"is--xl"} id={"add"} fnc={() => {}} withClose={false} isOpen={openC}>
              {
                (cover?.status !== "error" && cover?.progress < 100) && ((images.filter((el: any) => el.status !== "error").filter((el: any) => el.progress >= 100).length + videos.filter((el: any) => el.status !== "error").filter((el: any) => el.progress >= 100).length) < 1) ? (
                  <div className={"kbn-modal-files-status"}>
                    <h2>Televersement des fichiers en cours</h2>
                    <div className="kbn-modal-files-status__groups ">
                      <div className="kbn-modal-files-status__group kbn-modal-files-status-group">
                        <h3>Photo de couverture</h3>
                        <div className="kbn-modal-files-status-group__list">
                          <div className="kbn-modal-files-status-file">
                            <div className="kbn-modal-files-status-file__preview">
                              <img src={coverPreview} alt="article"/>
                            </div>
                            <div className="kbn-modal-files-status-file__container">
                              <strong>{cover?.name}</strong>
                              <div className="kbn-modal-files-status-file__progess kbn-modal-files-status-file-progess">
                                <div className={`kbn-modal-files-status-file-progess__container ${cover?.status === "error" ? "is--error" : cover?.progress >= 100 ? "is--completed" : "is--progress"}`} style={{
                                  width: `${cover?.progress}%`
                                }}></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                      <div className="kbn-modal-files-status__group kbn-modal-files-status-group">
                        <h3>Images</h3>
                        <div className="kbn-modal-files-status-group__list">
                          {
                            images.length > 0 ? (
                              images.map((item: any, index: number) => (
                                <div className="kbn-modal-files-status-file" key={index}>
                                  <div className="kbn-modal-files-status-file__preview">
                                    <img src={imagesPreview[index]} alt="article"/>
                                  </div>
                                  <div className="kbn-modal-files-status-file__container">
                                    <strong>{item.name}</strong>
                                    <div className="kbn-modal-files-status-file__progess kbn-modal-files-status-file-progess">
                                      <div className={`kbn-modal-files-status-file-progess__container ${item.status === "error" ? "is--error" : item.progress >= 100 ? "is--completed" : "is--progress"}`} style={{
                                        width: `${item.progress}%`
                                      }}></div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <strong>Aucune images</strong>
                            )
                          }
                        </div>
                      </div>
                      <div className="kbn-modal-files-status__group kbn-modal-files-status-group">
                        <h3>Videos</h3>
                        <div className="kbn-modal-files-status-group__list">
                          {
                            videos.length > 0 ? (
                              videos.map((item: any, index: number) => (
                                <div className="kbn-modal-files-status-file" key={index}>
                                  <div className="kbn-modal-files-status-file__preview">
                                    <video src={videosPreview[index]} controls={false}/>
                                  </div>
                                  <div className="kbn-modal-files-status-file__container">
                                    <strong>{item.name}</strong>
                                    <div className="kbn-modal-files-status-file__progess kbn-modal-files-status-file-progess">
                                      <div className={`kbn-modal-files-status-file-progess__container ${item.status === "error" ? "is--error" : item.progress >= 100 ? "is--completed" : ""}`} style={{
                                        width: `${item.progress}%`
                                      }}></div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <strong>Aucune images</strong>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={"kbn-modal-form-status"}>
                    <div className={"kbn-modal-form-status__container"}>
                        <div className={"kbn-modal-form-status__icon is--success"}>
                            <i className="icon-party-bell"/>
                        </div>
                        <div className={"kbn-modal-form-status__text"}>
                            <h2>Ajout d'un produit</h2>
                            <p>Votre produit vient d'être créé</p>
                            <div className={"kbn-modal-form-status__action"}>
                                <Button
                                  btnType={"button"}
                                  label={"Revenir vers la liste"}
                                  btnStyle={"is--neutral"}
                                  active={true}
                                  withIconLeft={false}
                                  onClick={() => {
                                    navigate(links.products)
                                  }}
                                />
                            </div>
                        </div>
                    </div>
                  </div>
                )
              }
            </ModalLayout>

          </div>
        </div>
      </MenuLayout>
    )
}
  