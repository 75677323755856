import { FormEvent, useContext, useEffect, useState } from "react";
import { AlertModule, Button, Textfield } from "../../../../components";
import { AuthLayout } from "../../../../layout";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { UserAuthContext } from "../../../../../context/AuthContext";
import { links } from "../../../../routes";
import { login } from "../../../../../app/controller/auth/auth.slice";

export const LoginPage = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const url = searchParams.get('url')
    
    const [data, setData] = useState<any>({
      email: "", password: ""
    })
    let navigate = useNavigate()

    const dispatch = useAppDispatch();
    const { isLoginLoading, isLoginError, isLoginSuccess, message } = useAppSelector((state) => state.auth);
    const { token, setRedirectTo } = useContext(UserAuthContext); 
    
    useEffect(() => {
      if(token){
        navigate(url || links.home)
      }
    }, [token, navigate, url]);

    useEffect(() => {
      setRedirectTo(url || links.home)
    }, [setRedirectTo, url]);

    const [error, setError] = useState({
        field: "",
        message: ""
    })

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if(!data.email){
          setError({
              field: "email",
              message: "Veuillez renseigner ce champs"
          })
          return
      }
      if(!data.password){
          setError({
              field: "password",
              message: "Veuillez renseigner ce champs"
          })
          return
      }
      setError({
          field: "",
          message: ""
      })
    dispatch(login(data))
  }

  useEffect(() => {
    if(isLoginSuccess){
      window.location.reload()
    }
  }, [navigate, isLoginSuccess]);


  return (
    <AuthLayout>
      <form onSubmit={handleSubmit} className={"kbn-auth-form"}>
          {
            isLoginError ? (
              <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
            ) : null
          }
          <div className='kbn-auth-form__fields'>
            <div className='kbn-auth-form__field'>
              <Textfield
                  id={"email"}
                  value={data.email}
                  require={true}
                  changeValue={(val: string) => setData({...data, email: val})}
                  type={"text"}
                  label={"Email"}
                  error={error}
                  placeholder={"Veuillez insérer votre email"}
              />
            </div>
            <div className='kbn-auth-form__field'>
                <Textfield
                    id={"password"}
                    value={data.password}
                    require={true}
                    changeValue={(val: string) => setData({...data, password: val})}
                    type={"password"}
                    label={"Mot de passe"}
                    placeholder={"Veuillez insérer votre mot de passe"}
                    error={error}
                />
            </div>
          </div>
          <div className='kbn-auth-form__action'>
            <Button btnType={"submit"} label={isLoginLoading ? "Connexion en cours..." : "Connexion"} btnStyle={"is--primary"} active={!isLoginLoading} withIconLeft={isLoginLoading} iconLeftClass={"icon-spin"}/>
          </div>
          <div className='kbn-auth-form__info'>
            <p>En cas de perte de votre mot de passe. Veuillez contacter un des administrateurs</p>
          </div>
      </form>
    </AuthLayout>
  )
}
