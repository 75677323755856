import { AccountPage, AdminPage, AdminsPage, CategoriesPage, CustomPage, EditInformationPage, EditPasswordPage, ErrorPage, HomePage, LoginPage, NotifPage, ProductCreatePage, ProductEditPage, ProductPage, ProductsPage, SubcategoriesPage, UserPage, UsersPage } from "../../pages";

import { links } from "./links";

export const routes = [
    {
        path: links.home,
        Component: HomePage
    },
    {
        path: links.login,
        Component: LoginPage
    },
    {
        path: links.account,
        Component: AccountPage
    },
    {
        path: links.account_custom,
        Component: CustomPage
    },
    {
        path: links.account_info,
        Component: EditInformationPage
    },
    {
        path: links.account_password,
        Component: EditPasswordPage
    },
    {
        path: links.notif,
        Component: NotifPage
    },
    {
        path: links.admins,
        Component: AdminsPage
    },
    {
        path: links.admin + ":slug",
        Component: AdminPage
    },
    {
        path: links.users,
        Component: UsersPage
    },
    {
        path: links.user + ":slug",
        Component: UserPage
    },
    {
        path: links.products,
        Component: ProductsPage
    },
    {
        path: links.product_create,
        Component: ProductCreatePage
    },
    {
        path: links.product + ":slug",
        Component: ProductPage
    },
    {
        path: links.product_edit + ":slug",
        Component: ProductEditPage
    },
    {
        path: links.categories,
        Component: CategoriesPage
    },
    {
        path: links.subcategories,
        Component: SubcategoriesPage
    },
    {
        path: links.error,
        Component: ErrorPage
    },
]