import { Link, useNavigate } from "react-router-dom";

import { FormEvent, useContext, useEffect, useState } from "react";
import { UserAuthContext } from "../../../../../context/AuthContext";
import { links } from "../../../../routes";
import { MenuLayout } from "../../../../layout";
import { deleteCategory, getCategories, reset, setCategory, updateCategory } from "../../../../../app/controller/category/category.slice";
import { AlertModule, Button, ModalLayout, Selectfield, Textfield } from "../../../../components";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { optionifyCategories } from "../../../../utils/functions";
import { MeditationCuate } from "../../../../assets/illustration";
import { ImportModal } from "../List";

const AddModal = ({categories, closeFnc}:{categories: any[],closeFnc: (val: boolean) => void}) => {
  const [data, setData] = useState<any>({
    label: "",
    description: "" ,
    parentId: ""
  })
  const dispatch = useAppDispatch();
  const { isCreateLoading, isCreateSuccess, isCreateError, message } = useAppSelector((state) => state.category);

  const [closeM, setCloseM] = useState<boolean>(false)
  const [error, setError] = useState<any>({
      field: "",
      message: ""
  })


  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if(!data.label){
          setError({
              field: "label",
              message: "Veuillez renseigner ce champs"
          })
          return
      }
      

      setError(null)
      setCloseM(true)
      dispatch(setCategory(data))
  }

  const closeModal = () => {
    setData(
      {
        label: "",
        description:  "" ,
        parentId: "",
      }
    )
    closeFnc(false)
    setCloseM(false)
  }

  return (
    <ModalLayout size={"is--md"} isOpen={true} id={"add-modal"} withClose={!isCreateLoading} fnc={() => closeModal()}>
      {
        isCreateSuccess && closeM ? (
          <div className={"kbn-modal-form-status"}>
              <div className={"kbn-modal-form-status__container"}>
                  <div className={"kbn-modal-form-status__icon is--success"}>
                      <i className="icon-party-bell"/>
                  </div>
                  <div className={"kbn-modal-form-status__text"}>
                      <h2>Création d'une sous-categorie</h2>
                      <p>La csous-ategories a été créée.</p>
                        <div className={"kbn-modal-form-status__action"}>
                            <Button
                                btnType={"button"}
                                label={"Fermer..."}
                                btnStyle={"is--neutral"}
                                active={true}
                                withIconLeft={false}
                                onClick={() => {
                                    closeModal()
                                }}
                            />
                        </div>
                        
                  </div>
              </div>
          </div>
        ) : (
          <div className={"kbn-modal-form"}>
            <div className={"kbn-modal-form__header"}>
              <h2>Ajout d'une sous-categorie</h2>
            </div>
            <form className={"kbn-modal-form__container"} onSubmit={handleSubmit}>
              {
                closeM && isCreateError ? (
                    <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
                ) : null
              }
              <div className={"kbn-modal-form__fields"}>
                <div className={"kbn-modal-form__field"}>
                    <Textfield
                        id={"label"}
                        value={data.label}
                        require={true}
                        changeValue={(value: any) => setData({...data, label: value})}
                        type={"text"}
                        label={"Nom"}
                        placeholder={"Eg: Immobilier"}
                        error={error}
                    />
                </div>
                <div className={"kbn-modal-form__field"}>
                    <Selectfield
                        id={"parentId"}
                        value={data.parentId}
                        require={true}
                        changeValue={(value: any) => setData({...data, parentId: value})}
                        tabs={categories}
                        label={"Categorie"}
                        placeholder={"Eg: Immobilier"}
                        error={error}
                    />
                </div>
                <div className={"kbn-modal-form__field"}>
                    <Textfield
                        id={"description"}
                        value={data.description}
                        require={false}
                        changeValue={(value: any) => setData({...data, description: value})}
                        type={"textarea"}
                        label={"Description"}
                        placeholder={"Decrire la sous-categorie"}
                        error={error}
                    />
                </div>
               
                
              </div>

              <div className={"kbn-modal-form__action"}>
                <Button btnType={"submit"} label={isCreateLoading ? "Creation en cours..." : "Enregistrer et ajouter"} btnStyle={"is--primary"} active={!isCreateLoading} withIconLeft={true} iconLeftClass={isCreateLoading ? "icon-spin" : ""} />
              </div>
            </form>
          </div>
        )
      }
    </ModalLayout>
  )
}
const EditModal = ({categories, item, closeFnc}:{categories: any[] ,item:any, closeFnc: (val: boolean) => void}) => {
  const [data, setData] = useState<any>({
    label: item.label || "",
    description: item.description || "" ,
    id: item.id,
    parentId: item.parent?.id,
  })
  const dispatch = useAppDispatch();
  const { isEditSuccess, isEditError, isEditLoading, message } = useAppSelector((state) => state.category);

  const [closeM, setCloseM] = useState<boolean>(false)
  const [error, setError] = useState<any>({
      field: "",
      message: ""
  })


  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if(!data.label){
          setError({
              field: "label",
              message: "Veuillez renseigner ce champs"
          })
          return
      }
      

      setError(null)
      setCloseM(true)
      dispatch(updateCategory(data))
  }

  const closeModal = () => {
    setData(
      {
        label: "",
        description:  "" ,
        id: "",
        parentId: "",
      }
    )
    closeFnc(false)
    setCloseM(false)
  }

  return (
    <ModalLayout size={"is--md"} isOpen={true} id={"add-modal"} withClose={!isEditLoading} fnc={() => closeModal()}>
      {
        isEditSuccess && closeM ? (
          <div className={"kbn-modal-form-status"}>
              <div className={"kbn-modal-form-status__container"}>
                  <div className={"kbn-modal-form-status__icon is--success"}>
                      <i className="icon-party-bell"/>
                  </div>
                  <div className={"kbn-modal-form-status__text"}>
                      <h2>Modification d'une sous-categorie</h2>
                      <p>Les modifications ont été enregistrées.</p>
                        <div className={"kbn-modal-form-status__action"}>
                            <Button
                                btnType={"button"}
                                label={"Fermer..."}
                                btnStyle={"is--neutral"}
                                active={true}
                                withIconLeft={false}
                                onClick={() => {
                                    closeModal()
                                }}
                            />
                        </div>
                        
                  </div>
              </div>
          </div>
        ) : (
          <div className={"kbn-modal-form"}>
            <div className={"kbn-modal-form__header"}>
              <h2>Modification d'une categorie</h2>
            </div>
            <form className={"kbn-modal-form__container"} onSubmit={handleSubmit}>
              {
                closeM && isEditError ? (
                    <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
                ) : null
              }
              <div className={"kbn-modal-form__fields"}>
                <div className={"kbn-modal-form__field"}>
                    <Textfield
                        id={"label"}
                        value={data.label}
                        require={true}
                        changeValue={(value: any) => setData({...data, label: value})}
                        type={"text"}
                        label={"Nom"}
                        placeholder={"Eg: Immobilier"}
                        error={error}
                    />
                </div>
                <div className={"kbn-modal-form__field"}>
                    <Selectfield
                        id={"parentId"}
                        value={data.parentId}
                        require={true}
                        changeValue={(value: any) => setData({...data, parentId: value})}
                        tabs={categories}
                        label={"Categorie"}
                        placeholder={"Eg: Immobilier"}
                        error={error}
                    />
                </div>
                <div className={"kbn-modal-form__field"}>
                    <Textfield
                        id={"description"}
                        value={data.description}
                        require={false}
                        changeValue={(value: any) => setData({...data, description: value})}
                        type={"textarea"}
                        label={"Description"}
                        placeholder={"Decrire la sous-categorie"}
                        error={error}
                    />
                </div>
               
                
              </div>

              <div className={"kbn-modal-form__action"}>
                <Button btnType={"submit"} label={isEditLoading ? "Modification en cours..." : "Enregistrer les modifications"} btnStyle={"is--primary"} active={!isEditLoading} withIconLeft={true} iconLeftClass={isEditLoading ? "icon-spin" : ""} />
              </div>
            </form>
          </div>
        )
      }
    </ModalLayout>
  )
}
const DeleteModal = ({item, closeFnc}:{item: any, closeFnc: (val: boolean) => void}) => {
  const dispatch = useAppDispatch();
  const {isDeleteSuccess, isDeleteLoading, isDeleteError, message } = useAppSelector((state) => state.category);
  
  const [statut, setStatut] = useState<boolean>(false)
  const closeModal = () => {
    if(isDeleteSuccess && statut){
      window.location.reload()
    }
    setStatut(false)
    closeFnc(false)
  }

  return (
    
    <ModalLayout size={"is--md"} isOpen={true} id={"add-modal"} withClose={!isDeleteLoading} fnc={() => closeModal()}>
      <div className={"kbn-modal-form"}>
        {
          statut && isDeleteSuccess ? (
            <div className={"kbn-modal-form-status"}>
                <div className={"kbn-modal-form-status__container"}>
                    <div className={"kbn-modal-form-status__icon is--success"}>
                        <i className="icon-party-bell"/>
                    </div>
                    <div className={"kbn-modal-form-status__text"}>
                        <h2>Suppression d'une sous-categorie</h2>
                        <p>L'element a bien été supprimé avec succès</p>
                        <div className={"kbn-modal-form-status__action"}>
                            <Button
                                btnType={"button"}
                                label={ "Fermer..."}
                                btnStyle={"is--neutral"}
                                active={true}
                                withIconLeft={false}
                                onClick={() => {
                                    closeModal()
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
          ) : (
            <div className={"kbn-modal-form-status"}>
                <div className={"kbn-modal-form-status__container"}>
                    <div className={"kbn-modal-form-status__icon is--danger"}>
                      <i className="icon-box-archive"/>
                    </div>
                    <div className={"kbn-modal-form-status__text"}>
                        <h2>Suppression d'une sous-categorie</h2>
                        {
                            statut && isDeleteError ? (
                              <p>
                                  <strong>Ouppss!!!</strong> <br/>
                                  <span>{message}</span>
                              </p>
                            ) : (
                                <p>Souhaitez-vous vraiment archiver cet élément</p>
                            )
                        }
                        <div className={"kbn-modal-form-status__action"}>
                            {
                                statut && isDeleteError ? (
                                    <Button
                                        btnType={"button"}
                                        label={isDeleteLoading ? "Envoi en cours" : "Réessayer..."}
                                        btnStyle={"is--danger"}
                                        active={!isDeleteLoading}
                                        withIconLeft={true}
                                        iconLeftClass={isDeleteLoading ? "icon-spin" : "icon-box-archive"}
                                        onClick={() => {
                                          
                                          setStatut(true)
                                          dispatch(deleteCategory(item.id))
                                        }}
                                    />
                                ) : (
                                    <Button
                                        btnType={"button"}
                                        label={isDeleteLoading ? "Suppression en cours" : "Archiver"}
                                        btnStyle={"is--danger"}
                                        active={!isDeleteLoading}
                                        withIconLeft={true}
                                        iconLeftClass={isDeleteLoading ? "icon-spin" : "icon-box-archive"}
                                        onClick={() => {
                                          
                                          setStatut(true)
                                          dispatch(deleteCategory(item.id))
                                        }}
                                    />
                                )
                            }

                        </div>
                    </div>
                </div>
            </div>
          )
        }
      </div>
    </ModalLayout>
  )
}

export const SubcategoriesPage = () => {
    const { token } = useContext(UserAuthContext);
    let navigate = useNavigate()

    useEffect(() => {
      if(!token){
        navigate(links.login + `?url=${links.subcategories}`)
      }
    }, [token, navigate]);
    const [search, setSearch] = useState<string>("")
    const [numberItem, setNumberItem] = useState<number>(20)
    const [page, setPage] = useState<number>(1)

    const [addModal, setAddModal] = useState<boolean>(false)
    const [editModal, setEditModal] = useState<boolean>(false)
    const [deleteModal, setDeleteModal] = useState<boolean>(false)

    const [importModal, setImportModal] = useState<boolean>(false)

    const [itemModal, setItemModal] = useState<any>(null)
    const dispatch = useAppDispatch();
    const [chooseApercu, setChosseApercu] = useState<string>("grid")
    
    const { isLoading, isSuccess, isError, categories, categories_info, message } = useAppSelector((state) => state.category);
  
    useEffect(() => {
      if(page && page >= 0 && numberItem && numberItem > 1){
        dispatch(getCategories({
          page: page - 1,
          size: numberItem,
        }))
      }
      return () => {
          dispatch(reset())
      }
    }, [dispatch, page, numberItem])

    return (
      <MenuLayout title="Sous-categories" isMin={true}>
        <div className='kbn-page'>
          <div className='kbn-page-arianne'>
            <Link to={links.home}>
              <span>Accueil</span>
            </Link>
            <span>/</span>
            <p className='active'>
              <span>Sous-categories</span>
            </p>
          </div>
          <div className='kbn-page-title'>
            <h1>Les sous-categories</h1>
          </div>
          <div className='kbn-page-actions'>
            <div className='kbn-page-actions__main'>
              <div className='kbn-page-action'>
                <Button btnType={'button'} label={'Ajouter'} btnStyle={'is--primary'} active={true} withIconRight={true} iconRightClass=' icon-plus' onClick={() => setAddModal(true)}/>
              </div>
              <div className='kbn-page-action'>
                <Button btnType={'button'} label={'Importer via csv'} btnStyle={'is--secondary'} active={true} withIconLeft={false} iconLeftClass=' icon-plus' onClick={() => setImportModal(true)}/>
              </div>
              <div className='kbn-page-search'>
                <i className="icon-search-alt"></i>
                <input type="search" placeholder='Rechercher....' value={search} onChange={(e) => setSearch(e.target.value)}/>
              </div>
            </div>
            <div className='kbn-page-actions__side'>
              <div className='kbn-page-numbers'>
                <span>Nombre à afficher</span>
                <input type="number" value={numberItem} onChange={(e) => setNumberItem(parseInt(e.target.value))} min={2} />
              </div>
              {
                categories_info?.totalPages > 1 ? (
                  <div className='kbn-page-numbers'>
                    <span>Page: </span>
                    <input type="number" value={page} onChange={(e) => setPage(parseInt(e.target.value))} min={1} />
                    <span>/ {categories_info?.totalPages || ""}</span>
                  </div>
                ) : (
                  <div className='kbn-page-numbers'>
                    <span>Page: 1/1</span>
                  </div>
                )
              }
              <div className='kbn-page-btns'>
                <button type='button' onClick={() => setChosseApercu("grid")} className={`kbn-page-btn ${chooseApercu === "grid" ? "is--active" : ""}`}>
                  <i className="icon-grid"></i>
                </button>
                <button type='button' onClick={() => setChosseApercu("list")} className={`kbn-page-btn ${chooseApercu === "list" ? "is--active" : ""}`}>
                  <i className="icon-list-ul-alt"></i>
                </button>
              </div>
            </div>
          </div>
          {
          isLoading ? (
            <div className={"kbn-loader2"}>
              <i className="icon-spin"/>
            </div>
          ) : (
            isError ? (
              <div className={"kbn-empty-status"}>
                <div className='kbn-empty-status__illu'>
                  <MeditationCuate/>
                </div>
                <div className='kbn-empty-status__container'>
                  <strong>Oupss!! Une erreur est survenue</strong>
                  {message}
                  <button type='button' onClick={() => window.location.reload()} style={{textDecoration: "underline"}}>Reactualiser...</button>
                </div>
              </div>
            ) : (
              isSuccess && categories && categories_info ? (
                <>
                  {
                    categories
                    .filter((el: any) => el.parent)
                    .filter((el: any) => el?.label?.toLowerCase().includes(search.toLowerCase()))
                    .length > 0 ? (
                      chooseApercu === "grid" ? (
                        <div className='kbn-page-grid'>
                          {
                            
                            categories
                            .filter((el: any) => el.parent)
                            .filter((el: any) => el?.label?.toLowerCase().includes(search.toLowerCase()))
                            .map((item, index) => (
                              <div className='kbn-page-grid-item' key={index}>
                                <div className='kbn-page-card'>
                                 
                                  <div className='kbn-page-card__container'>
                                    <strong>{item?.label}</strong>
                                    <p>Categorie: {item.parent?.label}</p>
                                  </div>
                                  <div className='kbn-page-card__actions'>
                                    <button type='button' className='kbn-page-card__action' onClick={() => {
                                      setItemModal(null)
                                      setItemModal(item)
                                      setEditModal(true)
                                    }}>
                                      <i className=" icon-pen-swirl"></i>
                                      <span>Modifier</span>
                                    </button>
                                   
                                    <button type='button' className='kbn-page-card__action is--danger' onClick={() => {
                                      setItemModal(null)
                                      setItemModal(item)
                                      setDeleteModal(true)
                                    }}>
                                      <i className=" icon-box-archive"></i>
                                      <span>Archiver</span>
                                    </button>
                                          
                                      
                                  </div>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      ) : (
                        <div className='kbn-page-list'>
                          <div className='kbn-page-list__row kbn-page-list-row is--header'>
                            <div className='kbn-page-list-col is--min'>
                              <strong>#</strong>
                            </div>
                            <div className='kbn-page-list-col is--auto'>
                              <strong>Sous-categorie</strong>
                            </div>
                            <div className='kbn-page-list-col is--auto'>
                              <strong>Categorie</strong>
                            </div>
                            <div className='kbn-page-list-col is--mid'>
                              <strong>Actions</strong>
                            </div>
                          </div>
                          <div className='kbn-page-list__body kbn-page-list-body'>
                            {
                              categories.filter((el: any) => el.parent)
                              .filter((el: any) => el?.label?.toLowerCase().includes(search.toLowerCase()))
                              .map((item, index) => (
                                <div className='kbn-page-list-row' key={index}>
                                  <div className='kbn-page-list-col is--min'>
                                    <strong>{index + 1}</strong>
                                  </div>
                                  <div className='kbn-page-list-col is--auto'>
                                    <div className='kbn-page-list-user'>
                                      <div className='kbn-page-list-user__container'>
                                        <strong>{item?.label}</strong>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='kbn-page-list-col is--auto'>
                                    <div className='kbn-page-list-user'>
                                      <div className='kbn-page-list-user__container'>
                                        <strong>{item?.parent?.label}</strong>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='kbn-page-list-col is--mid'>
                                    <button type='button' className='kbn-page-list-btn' onClick={() => {
                                      setItemModal(null)
                                      setItemModal(item)
                                      setEditModal(true)
                                    }}>
                                      <i className=" icon-pen-swirl"></i>
                                      <span>Modifier</span>
                                    </button>
                                    <button type='button' className='kbn-page-list-btn is--danger' onClick={() => {
                                      setItemModal(null)
                                      setItemModal(item)
                                      setDeleteModal(true)
                                    }}>
                                      <i className=" icon-box-archive"></i>
                                      <span>Archiver</span>
                                    </button>
                                     
                                  </div>

                                </div>
                              ))
                            }

                          </div>
                        </div>
                      )
                    ) : (
                      <div className={"kbn-empty-status"}>
                        <div className='kbn-empty-status__illu'>
                          <MeditationCuate/>
                        </div>
                        <div className='kbn-empty-status__container'>
                          <strong>La liste est vide.</strong>
                        </div>
                      </div>
                    )
                  }
                </>
              ) : null
            )
          )
        }
        {
          addModal && categories ? <AddModal closeFnc={setAddModal} categories={optionifyCategories(categories.filter((el: any) => el.parent === null))} /> : null
        }
        {
          itemModal && categories && editModal ? <EditModal categories={optionifyCategories(categories.filter((el: any) => el.parent === null))} item={itemModal} closeFnc={setEditModal} /> : null
        }
        {
          itemModal &&  deleteModal ? <DeleteModal item={itemModal} closeFnc={setDeleteModal} /> : null
        }

          {
            importModal ? <ImportModal closeFnc={setImportModal}/> : null
          }
        </div>
      </MenuLayout>
    )
  }
  