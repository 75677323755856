import { useNavigate } from "react-router-dom";
import { UserAuthContext } from "../../../../context/AuthContext";
import { AloneCuate } from "../../../assets/illustration";
import { MenuLayout } from "../../../layout";
import { links } from "../../../routes";
import { useContext, useEffect } from "react";

export const HomePage = () => {
    const { token, info } = useContext(UserAuthContext);
    let navigate = useNavigate()

    useEffect(() => {
      if(!token){
        navigate(links.login + `?url=${links.home}`)
      }
    }, [token, navigate]);
    
    return (
      <MenuLayout>
        <div className='kbn-page'>
          <div className='kbn-page-arianne'>
            <p className='active'>
              <span>Accueil</span>
            </p>
          </div>
  
          <div className='kbn-page-title'>
            <h1>🖐🏽</h1>
            <h2>Salut! {info?.firstName + " " + info?.lastName?.toUpperCase()}</h2>
            <span>Comment allez-vous</span>
          </div>
  
          <div className={"kbn-empty-status"}>
            <div className='kbn-empty-status__illu'>
              <AloneCuate/>
            </div>
            <div className='kbn-empty-status__container'>
              <strong>{"Page en cours de developpement"}</strong>
            </div>
          </div>
        </div>
      </MenuLayout>
    )
  }
  