import {FC, useEffect, useState} from "react";
import { SelectfieldParams } from "../interface/SelectfieldInterface";

export const Selectfield:FC<SelectfieldParams> = ({id, value, changeValue, require, placeholder, tabs, tooltip, error, label}) => {
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [search, setSearch] = useState<string>("")
  const [openOptions, setOptions] = useState<boolean>(false)

  useEffect(() => {
    if(error){
      if(id === error.field){
        setErrorMessage(error.message)
      }
    }
  }, [error, id])


  return (
    <div className={`kbn-selectfield ${errorMessage ? "st-error" : ""}`} >
      <label className={"kbn-selectfield__label kbn-selectfield-label"} htmlFor={`kbn-input-` + id}>
        <strong className={"kbn-selectfield-label__text"}>{label}</strong>
        
        {
          !require && (
            <span className={"kbn-selectfield__optionnal"}>(optional)</span>
          )
        }
      </label>
      <div className={"kbn-selectfield-field"}>
        <button type={"button"} className={"kbn-selectfield-field__container"} onClick={() => {
          let other: any = document.querySelector(".kbn-selectfield-field-options.is--active")
          if(other) other.classList.remove("is--active")
          setOptions(!openOptions)
        }}>
          {
            value ? (
              <span>
                {tabs.filter((el: any) => el.value === value) && tabs.filter((el: any) => el.value === value).length > 0 ? (
                  tabs.filter((el: any) => el.value === value)[0].name
                ) : ""}
              </span>
            ) : (
              <span>{placeholder}</span>
            )
          }
        </button>
        <div className={`kbn-selectfield-field-options ${openOptions ? "is--active" : ""}`} id={"kbn-opt-"+id}>
          <div className={"kbn-selectfield-field-options__search"}>
            <input type="search" placeholder={"search..."} value={search} onChange={(e) => setSearch(e.target.value)}/>
          </div>
          <div className={"kbn-selectfield-field-options__list"}>
            {
              tabs.filter((el) => el.name?.toLocaleLowerCase().includes(search.toLocaleLowerCase())).length > 0 ? (
                tabs.filter((el) => el.name?.toLocaleLowerCase().includes(search.toLocaleLowerCase())).map((item, index) => (
                  <div className={`kbn-selectfield-field-option ${item.value === value ? "is--active" : ""}`} key={index}>
                    <button className={"kbn-selectfield-field-option-text"} type={"button"} onClick={() => {
                      changeValue(item.value)
                      setOptions(false)
                    }}>
                      {
                        item.value === value ? (
                          <i className="kbn-icon-check"></i>
                        ) : null
                      }

                      <span>{item.name}</span>
                    </button>
                    {
                      item.value === value ? (
                        <button className={"kbn-selectfield-field-option-delete"} onClick={() => {
                          changeValue("")
                        }}>
                          <i className="kbn-icon-x"></i>
                        </button>
                      ) : null
                    }
                  </div>
                ))
              ) : (
                  <span>No items found...</span>
              )
            }
          </div>
        </div>
      </div>

      {
        errorMessage ? (
          <div className={"kubini-selectfield__message kubini-selectfield-message"}>
            {errorMessage}
          </div>
        ) : null
      }
    </div>
  );
};
