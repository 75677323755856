import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { useContext, useEffect, useState } from "react";
import { UserAuthContext } from "../../../../../context/AuthContext";
import { links } from "../../../../routes";
import { MenuLayout } from "../../../../layout";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { activeProduct, getProduct, reset } from "../../../../../app/controller/product/product.slice";
import { MeditationCuate } from "../../../../assets/illustration";

import { formatPrice } from "../../../../utils/functions";
import { Button, Toast } from "../../../../components";
import ImageGallery from "react-image-gallery";

const ImagesVideosBanner = ({images, videos}:{images: any[], videos: any[]}) => {
  
  const [files, setFiles] = useState<any[]>([])

  useEffect(() => {
    if(videos){
      videos.forEach((el: any) => {
        setFiles((list) => [...list, {
          original: el || "https://placehold.co/600x400?text=Video",
          thumbnail: "https://placehold.co/600x400?text=Video",
          renderItem: () => (
            <video src={el} controls />
          ),
        }])
      })
    }
  }, [videos])
  useEffect(() => {
    if(images){
      images.forEach((el: any) => {
        setFiles((list) => [...list, {
          original: el || "https://placehold.co/600x400?text=Pic",
          thumbnail: el,
        }])
      })
    }
  }, [images])
  
  return (
    <div className='kbn-product-banner__images kbn-product-banner-images'>
      <ImageGallery
        items={files}
        showPlayButton={false}
        showFullscreenButton={true}
        slideInterval={1000}
        slideOnThumbnailOver={true}
        showIndex={true}
        onPlay={() => {
          alert("slideshow is now playing!");
        }}
      />
    </div>
  )
}


export const ProductPage = () => {
    const {slug} = useParams()
    const { token } = useContext(UserAuthContext);
    let navigate = useNavigate()
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const tabs = searchParams.get('tabs')


    useEffect(() => {
      if(!token){
        navigate(links.login + `?url=${links.product + slug}`)
      }
    }, [token, navigate, slug]);
    const dispatch = useAppDispatch();
    const { isLoading, isSuccess, isError, isEditError, isEditLoading, isEditSuccess, product, message } = useAppSelector((state) => state.product);
    const [isActiveAc, setIsActiveAc] = useState<boolean>(false)

    useEffect(() => {
      if(slug){
        dispatch(getProduct(slug))
      }
      return () => {
        dispatch(reset())
      }
    }, [dispatch, slug]);
    
    return (
      <MenuLayout title="Product" isMin={true} here="product">
        <div className='kbn-page'>
          <div className='kbn-page-arianne'>
            <Link to={links.home}>
              <span>Accueil</span>
            </Link>
            <span>/</span>
            <Link to={links.products}>
              <span>Produit</span>
            </Link>
            <span>/</span>
            <p className='active'>
              <span>Aperçu d'un produit</span>
            </p>
          </div>
          {
            isLoading ? (
              <div className={"kbn-loader2"}>
                <i className="icon-spin"/>
              </div>
            ) : (
              isError? (
                <div className={"kbn-empty-status"}>
                  <div className='kbn-empty-status__illu'>
                    <MeditationCuate/>
                  </div>
                  <div className='kbn-empty-status__container'>
                    <strong>Oupss!! Une erreur est survenue</strong>
                    {message}
                    <button type='button' onClick={() => window.location.reload()} style={{textDecoration: "underline"}}>Reactualiser...</button>
                  </div>
                </div>
              ) : (
                isSuccess && product && (
                  <>
                    <div className='kbn-page-title'>
                      <h1>Apercu</h1>
                    </div>
                    <div className='kbn-product-banner'>
                      <ImagesVideosBanner images={product.images || []} videos={ product.videos || []}/>
                      <div className='kbn-product-banner__side'>
                        <div className='kbn-product-banner__info kbn-product-banner-info'>
                          <div className='kbn-product-banner-info__details'>
                            <div>Etat: <span className={`kbn-chip ${product.status === "ACTIVE" ? "is--success" : "is--warning"}`}>{product.status}</span></div>
                            <h2>
                              {product.label}
                            </h2>
                            <p>Categorie: <span>{product.category?.parent?.label}</span> - <span>{product.category?.label}</span></p>
                            <div className='kbn-product-banner-info__avis kbn-product-banner-info-avis'>
                              <div className='kbn-product-banner-info-avis__stars'>
                                <div className='kbn-product-banner-info-avis__star is--active'></div>
                                <div className='kbn-product-banner-info-avis__star is--active'></div>
                                <div className='kbn-product-banner-info-avis__star is--active'></div>
                                <div className='kbn-product-banner-info-avis__star is--active'></div>
                                <div className='kbn-product-banner-info-avis__star'></div>
                              </div>
                              <span>120k avis</span>
                            </div>
                          </div>
                          <div className='kbn-product-banner-info__orders'>
                            <div className='kbn-product-banner-info__price'>
                              <span>Prix minimum</span>
                              <strong>{formatPrice(product.minPrice, "yuan")}</strong>
                              <span>Prix maximum</span>
                              <strong>{formatPrice(product.maxPrice, "yuan")}</strong>
                            </div>
                            
                            <div className='kbn-product-banner-info__actions'>
                              <Button btnType={'button'} label={'Modifier'} btnStyle={'is--neutral'} active={true} withIconLeft={true} iconLeftClass='icon-pen-swirl' onClick={() => navigate(links.product_edit + product.id)}/>
                              <Button btnType={'button'} label={product?.status === "ACTIVE" ? 'Draft' : "Activer"} btnStyle={'is--primary'} active={!(isActiveAc && isEditLoading)} withIconLeft={false} iconLeftClass='icon-pen-swirl' onClick={() => {
                                setIsActiveAc(true)
                                dispatch(activeProduct({id: product?.id, status: product?.status === "ACTIVE" ? "DRAFT" : "ACTIVE" }))
                              }}/>
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                    <div className='kbn-product-navigation'>
                      <div className='kbn-product-navigation__nav kbn-product-navigation-nav'>
                        <button type='button' className={`kbn-product-navigation-nav__item ${tabs ? (tabs === "presentation" ? "is--active" : "") : "is--active"}`} onClick={() => navigate(links.product + slug + `?tabs=presentation`)}>
                          <span>Description</span>
                        </button>
                        <button type='button' className={`kbn-product-navigation-nav__item ${tabs ? (tabs === "comments" ? "is--active" : "") : ""}`} onClick={() => navigate(links.product + slug + `?tabs=comments`)}>
                          <span>Retours clients (120k)</span>
                        </button>
                      </div>
                      <div className={`kbn-product-navigation__container ${tabs ? (tabs === "presentation" ? "is--active" : "") : "is--active"}`}>
                        <div dangerouslySetInnerHTML={{ __html: product.description }}/>
                      </div>
                      <div className={`kbn-product-navigation__container ${tabs ? (tabs === "comments" ? "is--active" : "") : ""}`}></div>
                    </div>
                  </>
                )
              )
            )
          }
          {
            isActiveAc && isEditSuccess ? (
              <Toast id={"alert-notification"} status={"st-success"} fnc={() => setIsActiveAc(false)}>
                <p>{"Le statut du produit a été modifié"}</p>
              </Toast>
            ) : null
          }
          {
            isActiveAc && isEditError ? (
              <Toast id={"alert-notification"} status={"st-danger"} fnc={() => setIsActiveAc(false)}>
                <p>{message}</p>
              </Toast>
            ) : null
          }
        </div>
      </MenuLayout>
    )
  }
  