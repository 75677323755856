import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react'
import { MenuLayout } from '../../../../../layout'
import { Link, useNavigate } from 'react-router-dom'
import { links } from '../../../../../routes'
import { SettingMenu } from '../module/settingMenu'
import { AlertModule, Button, Selectfield, Textfield, Toast } from '../../../../../components'
import { sexe } from '../../../../../utils/constant'
import { useAppDispatch, useAppSelector } from '../../../../../../app/store'
import { capitalize } from '../../../../../utils/functions'
import { format } from 'date-fns'
import { reset, updateAvatar, updateInformation } from '../../../../../../app/controller/auth/auth.slice'
import { UserAuthContext } from '../../../../../../context/AuthContext'


const AvatarEdit = ({info}:{info: any}) => {
  const [fichier, setFichier] = useState<any>(null);
  const [size, setSize] = useState( 0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [error, setError] = useState<any>(null)

  const dispatch = useAppDispatch();
  const { isAvatarSuccess, isAvatarLoading, isAvatarError ,message } = useAppSelector((state) => state.auth);

  const handleCoverChangeFile = (e: ChangeEvent<HTMLInputElement>) =>{
      const file = e.target.files
      if(file && file.length > 0){
          let type: number = file[0].size
          setSize(type)
          let max: number = 1024 * 1024 * 4
          if(type < max){
            setFichier(file[0])
              // setCoverBase(file[0])
          }else{
              setError({
                field: "global",
                message: "Votre fichier ne doit pas dépasser 4MB"
              })
              return
          }
      }
  }



  useEffect(() => {
      if(fichier && info){
        const formData = new FormData();
        formData.append('avatar', fichier);

        let data = formData
        dispatch(updateAvatar(data))
        setError(null)
        setFichier(null)
      }
  }, [fichier, info]);

  useEffect(() => {
    if(error){
      setOpenModal(true)
    }
  }, [error]);

  useEffect(() => {
    if(isAvatarError){
      setOpenModal(true)
    }
  }, [isAvatarError]);

  useEffect(() => {
    if(isAvatarError){
      setOpenModal(true)
    }
  }, [isAvatarError]);


  return (
      <>
          <div className={"kbn-page-parametre-avatar"}>
            <div className='kbn-page-parametre-avatar__container'>
              <input type="file" accept='.jpg, .png,.jpeg' id='imageImg' onChange={handleCoverChangeFile}/>
              <label className={"kbn-page-parametre-avatar-image"} htmlFor={"imageImg"}>
                  {
                    isAvatarLoading ? (
                      <i className="icon-spin"></i>
                    ) : (
                      fichier ? (
                        <img src={URL.createObjectURL(fichier)} alt="imageUrl"/>
                      ) : (
                        info?.avatar ? (
                          <img src={info?.avatar} alt="imageUrl"/>
                        ) :
                        <i className="icon-user"></i>
                      )
                    )
                  }
              </label>
            </div>
            <div className='kbn-page-parametre-avatar__actions'>
              <label htmlFor="imageImg" className='kbn-button is--neutral'>
                <i className="kbn-icon-camera"></i>
                <span>Modifier la photo</span>
              </label>
              {/* <button type='button' className='kbn-button is--danger' onClick={() => {
                let data = {avatar : null}
              }}>
                <i className="kbn-icon-x"></i>
                <span>Supprimer la photo</span>
              </button> */}
            </div>
             {
                openModal && error && (
                  <Toast id={"avatar-alert"} status={"st-danger"} fnc={() => setOpenModal(false)}>
                    <p>{error?.message}</p>
                  </Toast>
                )
             }
             {
                openModal && isAvatarError && (
                  <Toast id={"avatar-alert"} status={"st-danger"} fnc={() => setOpenModal(false)}>
                    <p>{message}</p>
                  </Toast>
                )
             }
             {
                openModal && isAvatarSuccess && (
                  <Toast id={"avatar-alert"} status={"st-success"} fnc={() => setOpenModal(false)}>
                    <p>{"Votre photo de profile a été modifié"}</p>
                  </Toast>
                )
             }
          </div>
      </>

  )
}

const EditMyInformation = ({info}:{info: any}) => {
  const dispatch = useAppDispatch();
  const { isEditLoading, isEditSuccess, isEditError ,message } = useAppSelector((state) => state.auth);

  const [data, setData] = useState<any>({
    firstName: info?.firstName || "",
    lastName: info?.lastName || "",
    gender: info?.gender || "",
    phoneNumber: info?.phoneNumber || "",
    birthday: info?.birthday ? capitalize(format(new Date(info?.birthday), "yyyy-MM-dd")) : "",
  })

  const [openModal, setOpenModal] = useState<boolean>(false);

  const [error, setError] = useState<any>(null)


  const handleSumbit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if(!data.firstName){
      setError({
          field: "firstName",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.lastName){
      setError({
          field: "lastName",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.phoneNumber){
      setError({
          field: "phoneNumber",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    setError(null)
    dispatch(updateInformation(data))
  }

  useEffect(() => {
    if(error){
      setOpenModal(true)
    }
  }, [error]);

  return (
    <div className='kbn-page-parametre__box kbn-page-parametre-box'>
      <h2>Mes informations</h2>
      <form className='kbn-page-parametre-box__form' onSubmit={handleSumbit}>
        {
          isEditSuccess ? (
            <AlertModule status={"st-success"} title={"Succès!!!"} message={"Vos informations a été modifiées avec succès"}/>
          ) : (
              isEditError ? (
                <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
          )
        }    
        <div className='kbn-page-parametre-box__fields'>
          <div className='kbn-page-parametre-box__field'>
              <Textfield
                  id={"lastName"}
                  value={data.lastName}
                  require={true}
                  changeValue={(val: string) => setData({...data, lastName:  val})}
                  type={"text"}
                  label={"Nom de famille"}
                  error={error}
                  placeholder={"Veuillez insérer votre nom de famille"}
              />
          </div>
          <div className='kbn-page-parametre-box__field'>
              <Textfield
                  id={"firstName"}
                  value={data.firstName}
                  require={true}
                  changeValue={(val: string) => setData({...data, firstName: val})}
                  type={"text"}
                  label={"Prénom(s)"}
                  error={error}
                  placeholder={"Veuillez insérer votre(vos) prénom(s)"}
              />
          </div>
          <div className='kbn-page-parametre-box__field'>
              <Selectfield
                  id={"gender"}
                  value={data.gender}
                  require={false}
                  changeValue={(val: string) => setData({...data, gender: val})}
                  tabs={sexe}
                  label={"Genre"}
                  error={error}
                  placeholder={"Veuillez insérer votre genre"}
              />
          </div>
          
          <div className='kbn-page-parametre-box__field'>
              <Textfield
                  id={"birthday"}
                  value={data.birthday}
                  require={false}
                  changeValue={(val: string) => setData({...data, birthday: val})}
                  type={"date"}
                  label={"Date de naissance"}
                  error={error}
                  placeholder={"Veuillez insérer votre date de naissance"}
              />
          </div>
          
          <div className='kbn-page-parametre-box__field'>
            <div className='kbn-textfield is--unmodifiable'>
              <div className='kbn-textfield__label kbn-textfield-label'>
                <strong className={"kbn-textfield-label__text"}>{"Email"}</strong>
                <span className={"kbn-textfield__optionnal"}>(Non modifiable)</span>
              </div>
              <div className={"kbn-textfield__field is--unmodifiable"}>
                <span>{info?.email}</span>
              </div>
            </div>
          </div>
          
        <div className='kbn-page-parametre-box__field'>
          <Textfield
              id={"phoneNumber"}
              value={data.phoneNumber}
              require={true}
              changeValue={(val: string) => setData({...data, phoneNumber: val})}
              type={"phone"}
              label={"Telephone"}
              error={error}
              placeholder={"Veuillez insérer votre numero de telephone"}
          />
        </div>
          
        </div>
        <div className='kbn-page-parametre-box__action'>
          <Button btnType={"submit"} label={isEditLoading ? "Modification en cours..." : "Enregistrer les modifications"} btnStyle={"is--primary"} active={!isEditLoading} withIconLeft={isEditLoading} iconLeftClass={"icon-spin"}/>
        </div>
      </form>
      
      {
        openModal && (
          <Toast id={"avatar-alert"} status={"st-danger"} fnc={() => setOpenModal(false)}>
            <p>{error?.message}</p>
          </Toast>
        )
      }
    </div>
  )
}

export const EditInformationPage = () => {
  const { token, info } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.account_info}`)
    }
  }, [token, navigate]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(reset())
    }
  }, [dispatch])
  
  return (
    <MenuLayout title="Paramètre - Mes informations" isMin={true}>
      <div className='kbn-page is--grid'>
        <div className='kbn-page__side'>
          <SettingMenu here='info'/>
        </div>
        <div className='kbn-page__container'>

          <div className='kbn-page-arianne'>
            <Link to={links.home}>
              <span>Accueil</span>
            </Link>
            <span>/</span>
            <Link to={links.account}>
              <span>Mon compte</span>
            </Link>
            <span>/</span>
            <p className='active'>
              <span>Mes informations</span>
            </p>
          </div>

          <div className='kbn-page-title'>
            <h1>Mes informations</h1>
          </div>

          <div className='kbn-page-parametre'>
            <div className='kbn-page-parametre__side'>
              {info? <AvatarEdit info={info}/> : null}
            </div>
            <div className='kbn-page-parametre__container'>
              {info ? <EditMyInformation info={info}/> : null}
            </div>
          </div>

        </div>
      </div>
    </MenuLayout>
  )
}
