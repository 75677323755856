import React from 'react'
import { links } from '../../../../../routes'
import { NavLink } from 'react-router-dom'

export const SettingMenu = ({here}:{here: string}) => {
    let navItems = [
        {
            icon: "icon-user-pen",
            link: links.account_info,
            label: "Mes informations",
            here: "info",
        },
        {
            icon: "icon-shield",
            link: links.account_password,
            label: "Mot de passe",
            here: "password",
        },
        {
            icon: "icon-pen-swirl",
            link: links.account_custom,
            label: "Customisation",
            here: "custom",
        },
    ]
    return (
        <div className='kbn-submenu'>
            <div className='kbn-submenu__title'>
                <h2>Paramètres</h2>
            </div>
            <ul className='kbn-submenu__container'>
                {
                    navItems.map((item: any, index: number) => (
                        <li className="kbn-submenu__item" key={index}>
                            <NavLink to={item.link} className={here === item.here ? "active" : ""} end>
                                <i className={item.icon}></i>
                                <span>{item.label}</span>
                            </NavLink>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}
