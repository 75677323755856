export const links = {
    home: "/",
    login: "/connexion",
    account: "/mon-compte",
    account_info: "/paramatre-du-compte/mes-informations",
    account_password: "/paramatre-du-compte/mot-de-passe",
    account_custom: "/paramatre-du-compte/theme",
    notif: "/boite-de-reception",

    users: "/clients",
    user: "/client/",
    admins: "/administrators",
    admin: "/administrator/",

    categories: "/categories",
    subcategories: "/subcategories",

    products: "/products",
    product: "/product/",
    product_create: "/add-product",
    product_edit: "/edit-product/",

    error: "/*",
}