import React, { useContext, useEffect, useState } from 'react'
import { MenuLayout } from '../../../../layout'
import { AloneCuate } from '../../../../assets/illustration'
import { UserAuthContext } from '../../../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { links } from '../../../../routes';


export const NotifPage = () => {
  const { token } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.notif}`)
    }
  }, [token, navigate]);
  
  return (
    <MenuLayout title="Boite de reception" isMin={true}>
      <div className='kbn-page'>
        <div className='kbn-page-arianne'>
          <p className='active'>
            <span>Boite de reception</span>
          </p>
        </div>
        <div className='kbn-page-title'>
          <h1>Boite de reception</h1>
        </div>
        <div className={"kbn-empty-status"}>
          <div className='kbn-empty-status__illu'>
            <AloneCuate/>
          </div>
          <div className='kbn-empty-status__container'>
            <strong>{"Page en cours de developpement"}</strong>
          </div>
        </div>
      </div>
    </MenuLayout>
  )
}
