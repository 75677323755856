import React, {useState} from 'react';
import {AlertCardProps} from "../interface/AlertInterface";


export const AlertModule: React.FC<AlertCardProps> = ({status, message, withClose, title}) => {
    const [closeAlert, setCloseAlert] = useState<boolean>(false)
    return (
        <div className={`kbn-alert ${status}`} style={{display: closeAlert ? "none" : "flex"}}>
            <div className={"kbn-alert__icon"}>
                {
                    status === "st-info" ? (
                        <i className="icon-circle-question"/>
                    ) : (
                        status === "st-danger" ? (
                            <i className="icon-circle-information"/>
                        ) : (
                            status === "st-success" ? (
                                <i className="icon-party-bell"/>
                            ) : (
                                status === "st-warning" ? (
                                    <i className="icon-triangle-exclamation"/>
                                ) : null
                            )
                        )
                    )
                }
            </div>
            <div className={"kbn-alert__container"}>
                <strong>{title}</strong>
                <p>{message}</p>
            </div>
            {
                withClose && (
                    <button type={"button"} onClick={() => setCloseAlert(true)}>
                        <i className="kbn-icon-x"/>
                    </button>
                )
            }
        </div>
    );
};
