import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { APIURL, deleteProtecteItems, getProtecteItems, setProtecteItems, updateProtecteItems } from '../../utils';
import { ProductState } from '../../interface/GeneralStateInterface';
import axios from 'axios';

const URI = APIURL+"/product"
export const URIA = APIURL+"/admin/product"

const initialState: ProductState = {
    products: [],
    product: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    isCreateError: false,
    isCreateSuccess: false,
    isCreateLoading: false,

    isEditError: false,
    isEditSuccess: false,
    isEditLoading: false,

    isDeleteError: false,
    isDeleteSuccess: false,
    isDeleteLoading: false,

    isImportError: false,
    isImportLoading: false,
    isImportSuccess: false,

    isFileError: false,
    isFileLoading: false,
    isFileSuccess: false,

    message: null,
    products_info: null
}


export const setProduct = createAsyncThunk<any, Object>(
    'product/create',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URIA}`,
                content,
                token: tokens
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const importProduct = createAsyncThunk<any, Object>(
    'product/import-from-csv',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
        //     let data = {
        //         url: `${URIA}/csv`,
        //         content,
        //         token: tokens
        //     }
        //    await setProtecteItems(data)

            const response = await axios.post(`${URIA}/csv`, content, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${tokens}`,
                },
            });
            console.log(response)
            return response.data
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)


export const getProducts = createAsyncThunk<any, Object>(
    'product/list',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URIA}/list?${content?.categoryId ? `categoryId=${content?.categoryId}&` : ""}pageNumber=${content?.page}&limit=${content?.size}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const getProduct = createAsyncThunk<any, Object>(
    'product/show',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}?id=${content}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const updateProduct = createAsyncThunk<any, Object>(
    'product/update',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URIA}`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const activeProduct = createAsyncThunk<any, Object>(
    'product/active',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URIA}/active?id=${content?.id}`,
                token: tokens,
                content: {}
            }
            const reponse = await updateProtecteItems(data)

            return {...content, reponse}

        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const updateProductFile = createAsyncThunk<any, Object>(
    'product/update-file',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URIA}/files`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const addFileProduct = createAsyncThunk<any, Object>(
    'product/add-file',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URIA}/files/addfile?id=${content?.id}&fileName=${content?.fileName}`,
                token: tokens,
                content: {}
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const deleteFileProduct = createAsyncThunk<any, Object>(
    'product/delete-file',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
            
            let data = {
                url: `${URIA}/file?id=${content?.id}&url=${content?.url}`,
                token: tokens,
                content
            }
            return await deleteProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

export const deleteProduct = createAsyncThunk<any, Object>(
    'product/delete',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URIA}?id=${content}`,
                token: tokens,
                content
            }
            return await deleteProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)


export const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        reset: (state: any) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false

            state.isEditError = false
            state.isEditLoading = false
            state.isEditSuccess = false


            state.isDeleteError = false
            state.isDeleteLoading = false
            state.isDeleteSuccess = false

            state.isCreateLoading = false
            state.isCreateError = false
            state.isCreateSuccess = false

            state.isImportLoading = false
            state.isImportSuccess = false
            state.isImportError = false

            state.isFileLoading = false
            state.isFileError = false
            state.isFileSuccess = false

            state.message = null
        },
    },
    extraReducers: (builder) => {
        builder
        
        .addCase(getProducts.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getProducts.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.products_info = action.payload
            state.products = action.payload?.content
        })
        .addCase(getProducts.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(getProduct.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getProduct.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.product = action.payload
        })
        .addCase(getProduct.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.product = null
        })

        .addCase(updateProduct.pending, (state) => {
            state.isEditLoading = true
        })
        .addCase(updateProduct.fulfilled, (state, action) => {
            state.isEditLoading = false
            state.isEditSuccess = true
            // state.product = action.payload
        })
        .addCase(updateProduct.rejected, (state, action) => {
            state.isEditLoading = false
            state.isEditError = true
            state.message = action.payload
            // state.product = null
        })

        .addCase(activeProduct.pending, (state) => {
            state.isEditLoading = true
        })
        .addCase(activeProduct.fulfilled, (state, action) => {
            state.isEditLoading = false
            state.isEditSuccess = true
            state.product = {...state.product, status: action.payload.status}
        })
        .addCase(activeProduct.rejected, (state, action) => {
            state.isEditLoading = false
            state.isEditError = true
            state.message = action.payload
            // state.product = null
        })

        .addCase(updateProductFile.pending, (state) => {
            state.isEditLoading = true
        })
        .addCase(updateProductFile.fulfilled, (state, action) => {
            state.isEditLoading = false
            state.isEditSuccess = true
            // state.product = action.payload
        })
        .addCase(updateProductFile.rejected, (state, action) => {
            state.isEditLoading = false
            state.isEditError = true
            state.message = action.payload
            // state.product = null
        })

        .addCase(setProduct.pending, (state) => {
            state.isCreateLoading = true
        })
        .addCase(setProduct.fulfilled, (state, action) => {
            state.isCreateLoading = false
            state.isCreateSuccess = true
            state.products = [action.payload, ...state.products]
            state.product = action.payload
        })
        .addCase(setProduct.rejected, (state, action) => {
            state.isCreateLoading = false
            state.isCreateError = true
            state.message = action.payload
            state.product = null
        })

        .addCase(importProduct.pending, (state) => {
            state.isImportLoading = true
        })
        .addCase(importProduct.fulfilled, (state, action) => {
            state.isImportLoading = false
            state.isImportSuccess = true
            state.products = [...action.payload, ...state.products]
            state.product = action.payload
        })
        .addCase(importProduct.rejected, (state, action) => {
            state.isImportLoading = false
            state.isImportError = true
            state.message = action.payload
            // state.product = null
        })


        .addCase(addFileProduct.pending, (state) => {
            state.isFileLoading = true
        })
        .addCase(addFileProduct.fulfilled, (state, action) => {
            state.isFileLoading = false
            state.isFileSuccess = true
            // state.products = [...action.payload, ...state.products]
            // state.product = action.payload
        })
        .addCase(addFileProduct.rejected, (state, action) => {
            state.isFileLoading = false
            state.isFileError = true
            state.message = action.payload
            // state.product = null
        })


        .addCase(deleteFileProduct.pending, (state) => {
            state.isFileLoading = true
        })
        .addCase(deleteFileProduct.fulfilled, (state, action) => {
            state.isFileLoading = false
            state.isFileSuccess = true
            // state.products = [...action.payload, ...state.products]
            // state.product = action.payload
        })
        .addCase(deleteFileProduct.rejected, (state, action) => {
            state.isFileLoading = false
            state.isFileError = true
            state.message = action.payload
            // state.product = null
        })

        .addCase(deleteProduct.pending, (state) => {
            state.isDeleteLoading = true
        })
        .addCase(deleteProduct.fulfilled, (state, action) => {
            state.isDeleteLoading = false
            state.isDeleteSuccess = true
            state.product = action.payload
            state.products = state.products?.filter((el: any) => el.id !== action.payload?.id)
        })
        .addCase(deleteProduct.rejected, (state, action) => {
            state.isDeleteLoading = false
            state.isDeleteError = true
            state.message = action.payload
            state.product = null
        })
    },
})

export const { reset } = productSlice.actions
export default productSlice.reducer